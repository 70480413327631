import React from "react";
import "./advertiserhome.css";
import "./advertiserhome.responsive.css";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import {
  advertiserDashboardData,
  advertiserDeposit,
  advertiserTransationHistory,
  getAdvertisementData,
  listOfNetwork,
} from "../../../../redux/slices/AdminService";
import moment from "moment";

import { useEffect, useRef, useState } from "react";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import AdvertApexChart from "./AdvertApexChart";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { advertiserDepositSchema } from "../../../../validations/Validation";
import abi from "../../../../utils/abi.json";
import tokenAbi from "../../../../utils/token.json";
import { ethers } from "ethers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SkeletonTable from "../../../../components/common/skeletonTable/SkeletonTable";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#171423",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: 400,
};

export default function AdvertiserHome() {
  const navigate = PageIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [allDashboardData, setAllDashboardData] = useState({});
  const [allMainDashboardData, setAllMainDashboardData] = useState({});
  console.log(allDashboardData, "allDashboardData");
  const [transationHistoryData, setTrnsationHistoryData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // const [advertismentHistoryFilterData, setAdvertismentFilterData] = useState([])
  const [openTooltip, setOpenTooltip] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [allNetworksData, setAllNetworksData] = useState();
  const [depositAmountError, setDepositAmountError] = useState();
  const [walletBalance, setWalletBalance] = useState();
  const dispatch = PageIndex?.useDispatch();
  const [openDeposit, setOpenDeposit] = useState(false);
  const [depositResponse, setDepositResponse] = useState();
  const handleOpenDeposit = () => setOpenDeposit(true);
  const handleCloseDeposit = () => { setOpenDeposit(false); setDepositAmountError('') };
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { address, isConnected } = useWeb3ModalAccount();
  const [depositWaitingMessage, setDepositWaitingMessage] = useState();
  const [advertismentHistoryData, setAdvertisementHistoryData] = useState([]);
  const [advertisementFilter, setAdvertisementFilter] = useState(0);

  const [openCustom, setOpenCustom] = useState(false);
  const handleOpenCustom = () => setOpenCustom(true);
  const handleCloseCustom = () => setOpenCustom(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = React.useState([
    null,
    null,
  ]);

  const handleDateChange = (newValue) => {
    if (newValue[0] && newValue[1]) {
      const date = {
        from: dayjs(newValue[0]).format("YYYY-MM-DD"),
        to: dayjs(newValue[1]).format("YYYY-MM-DD"),
      };

      handleFilterData(3, date);
    }
    const formattedStartDate = newValue?.[0]
      ? dayjs(newValue[0]).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = newValue[1]
      ? dayjs(newValue[1]).format("YYYY-MM-DD")
      : "";
    // setSelectedDateRange([formattedStartDate ?? '', formattedEndDate ?? '']);
    setSelectedDateRange([newValue?.[0] ?? null, newValue?.[1] ?? null]);
  };
  // const [costumDateSelect,setCostomDateSelect]=useState("")
  // const [customFilter]
  const contractAddress = "0x55BfD4fA8bCF6aC5f36492Ea1dA09e9C6797D8Ab";
  const tokenAddress = "0x3379b4Ede5bfB762FaA203db0229Eb66D6376675";

  useEffect(() => {
    dispatch(advertiserDashboardData()).then((res) => {
      if (res?.payload?.status === 200) {
        const activeTotalShownCountByDate =
          Object.keys(res?.payload?.data?.activeTotalShownCountByDate)?.length >
            0
            ? Object.entries(
              res?.payload?.data?.activeTotalShownCountByDate
            ).map(([key, value]) => {
              return { x: key, y: value };
            })
            : [];
        const totalShownCountByDate =
          Object.keys(res?.payload?.data?.totalShownCountByDate)?.length > 0
            ? Object.entries(res?.payload?.data?.totalShownCountByDate).map(
              ([key, value]) => {
                return { x: key, y: value };
              }
            )
            : [];
        const formattedData = {
          ...res?.payload?.data,
          activeTotalShownCountByDate,
          totalShownCountByDate,
        };
        setAllDashboardData(formattedData);
        setAllMainDashboardData(formattedData);

        setLoading(false);
      }
    });
    dispatch(advertiserTransationHistory()).then((res) => {
      const data = res?.payload?.data?.slice(0, 10);
      setTrnsationHistoryData(data);
      if (res?.payload) {
        setLoading(false);
      }
    });
    dispatch(getAdvertisementData()).then((res) => {
      const data = res?.payload?.data?.slice(0, 10);
      setAdvertisementHistoryData(data);
    });
  }, []);

  useEffect(() => {
    let filteredItems = transationHistoryData;
    if (search) {
      filteredItems = transationHistoryData?.filter((item) => {
        return !search
          ? item
          : item?.from?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.to?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.transactionHash
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.value
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.url
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.gasless
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.advertisementId?.title
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.gasFees
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase());
      });
    }

    setFilteredData(filteredItems);
  }, [transationHistoryData, search]);

  // useEffect(() => {
  //   let filterItems = advertismentHistoryData;
  //   if (search) {
  //     filterItems = advertismentHistoryData?.filter((item) => {
  //       const formattedStartDate = moment(item?.startDate)
  //         .format("MMM D, YYYY")
  //         .toLowerCase();
  //       const formattedEndDate = moment(item?.endDate)
  //         .format("MMM D, YYYY")
  //         .toLowerCase();
  //       return !search
  //         ? item
  //         : item?.title.toString()
  //           .toLowerCase()
  //           .includes(search.toString().toLowerCase()) ||
  //         item?.totalGasFees.toString()
  //           .toLowerCase()
  //           .includes(search.toString().toLowerCase()) ||
  //         item?.totalViews.toString()
  //           .toLowerCase()
  //           .includes(search.toString().toLowerCase()) ||
  //         item?.costPerView.toString()
  //           .toLowerCase()
  //           .includes(search.toString().toLowerCase()) ||
  //         formattedStartDate.includes(search.toString().toLowerCase()) ||
  //         formattedEndDate.includes(search.toString().toLowerCase());

  //     })
  //   }
  //   setAdvertismentFilterData(filterItems)
  // }, [advertismentHistoryData, search])
  useEffect(() => {
    dispatch(listOfNetwork()).then((res) => {
      if (res?.payload?.status === 200) {
        setAllNetworksData(res?.payload?.data);
        setLoading(false);
      }
    });
  }, [depositResponse]);

  const formRef = useRef();
  const initialValues = {
    depositAmount: "",
    network: "",
    token: "",
  };

  const walletFunction = async () => {
    try {
      if (!walletProvider) {
        throw new Error("No wallet provider found");
      }

      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();

      if (!address) {
        throw new Error("No address found");
      }
      const balance = await signer.getBalance()
      // const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
      // const balance = await tokenContract.balanceOf(address);
      setWalletBalance(balance.toString());
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };



  useEffect(() => {
    if (isConnected) {
      walletFunction();
    }
  }, [isConnected]);

  const handleDeposit = async (values) => {
    if (isConnected && !depositAmountError) {
      setBtnLoading(true);
      const data = {
        depositAmount: values?.depositAmount,
        network: values?.network,
        token: "1",
        walletAddress: address,
      };
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
      setDepositWaitingMessage("Please wait for approval...");

      try {
        const weiValue = ethers.utils.parseEther(values?.depositAmount);
        // const transation = await tokenContract.approve(
        //   contractAddress,
        //   weiValue
        // );
        // await transation.wait();
        // setDepositWaitingMessage("Please wait for transaction...");

        // const tx = await contract.deposit(weiValue);
        // await tx.wait();

        // if (depositOption === 0) {
          // Deposit with ETH
        const tx = await contract.deposit(0, weiValue, {
          value: weiValue, // Convert amount to wei
          });
          await tx.wait(); // Wait for transaction to be mined
        // } else {
          // Deposit with ERC20 tokens
          // const transaction = await contract.deposit(
          //   depositOption,
          //   ethers.utils.parseUnits(amount, 18) // Assuming token has 18 decimals
          // );
          // await transaction.wait(); // Wait for transaction to be mined
        // }

        dispatch(advertiserDeposit(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setDepositResponse(res?.payload?.data);
            setDepositWaitingMessage("");

            setBtnLoading(false);
            handleCloseDeposit();
          }
        });
      } catch (error) {
        console.log(error.code, "error", error);
        if (error.code == "ACTION_REJECTED") {
          PageIndex.toast.error("User rejected transaction", {
            toastId: "customId",
          });
        } else {
          PageIndex.toast.error("Something went wrong", {
            toastId: "customId",
          });
        }

        handleCloseDeposit();
        setBtnLoading(false);
        setDepositWaitingMessage("");
      }
    }
  };

  console.log(allDashboardData, "allDashboardData");
  const handleFilterData = (value, customDate) => {
    // console.log(customDate, "date304")
    if (value === 0) {
      setAllDashboardData(allMainDashboardData);
      setSelectedDateRange([null, null]);
      return;
    }

    const startDate = moment()
      .subtract(value - 1, "days")
      .format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");

    if (value == 3 && customDate?.from && customDate?.to) {
      const activeTotalShownCountByDate =
        allMainDashboardData?.activeTotalShownCountByDate?.length > 0 &&
        allMainDashboardData?.activeTotalShownCountByDate.filter(
          (item) => item?.x >= customDate?.from && item?.x <= customDate?.to
        );

      const totalShownCountByDate =
        allMainDashboardData?.totalShownCountByDate?.length > 0 &&
        allMainDashboardData?.totalShownCountByDate.filter(
          (item) => item?.x >= customDate?.from && item?.x <= customDate?.to
        );

      setAllDashboardData((prev) => ({
        ...prev,
        activeTotalShownCountByDate,
        totalShownCountByDate,
      }));
      return;
    }

    if ([7, 14]?.includes(value)) {
      const activeTotalShownCountByDate =
        allMainDashboardData?.activeTotalShownCountByDate?.length > 0 &&
        allMainDashboardData?.activeTotalShownCountByDate.filter(
          (item) => item?.x >= startDate && item?.x <= currentDate
        );

      const totalShownCountByDate =
        allMainDashboardData?.totalShownCountByDate?.length > 0 &&
        allMainDashboardData?.totalShownCountByDate.filter(
          (item) => item?.x >= startDate && item?.x <= currentDate
        );
      setSelectedDateRange([null, null]);

      setAllDashboardData((prev) => ({
        ...prev,
        activeTotalShownCountByDate,
        totalShownCountByDate,
      }));
    }
  };

  const stopWhiteSpace = (val) => {
    if (!val) {
      return "";
    }
    const checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    } else {
      return undefined;
    }
  };
  return (
    <>
      {loading ? (
        <MvpLoader color="#1B698E" loading={loading} />
      ) : (
        <>
          <Index.Box className="dashboard-content home-page-content">
            <Index.Grid
              container
              spacing={2}
              className="dashboard-grid-content"
            >
              <Index.Grid item xs={12} md={12} lg={12}>
                <Index.Box className="flex-dash-advertisement">
                  <Index.Typography
                    className="admin-page-title main-heading"
                    component="h2"
                    variant="h2"
                  >
                    Dashboard
                  </Index.Typography>

                  <Index.Box className="deposit-btn-main">
                    <Index.Box className="btn-main-primary login-btn-main">
                      <Index.Button
                        className="btn-primary login-btn"
                        onClick={() => open()}
                      >
                        {isConnected
                          ? `${address.slice(0, 5)}....${address.slice(-5)}`
                          : "Connect Wallet"}
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="btn-main-primary login-btn-main">
                      <Index.Button
                        className="btn-primary login-btn"
                        onClick={() =>
                          isConnected
                            ? handleOpenDeposit()
                            : PageIndex.toast.error("Please connect wallet", {
                              toastId: "customId",
                            })
                        }
                      >
                        <img
                          src={PageIndex.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Deposit
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>

            {/* <Index.Box className="dashboard-back-control"> */}

            <Index.Grid container columnSpacing={2}>
              <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                <Index.Box className="dashboard-back-control-custom card-main-colleft">
                  {/* <Index.Box className="copy-text-bg-main"> </Index.Box >  */}
                  <Index.Box className="graph-box-cont graph-advertisement">
                    <Index.Box className="flex-advertisemant-main graph-box-card-pd">
                      <Index.Grid container spacing={2}>
                        <Index.Grid item xs={12} sm={6} md={3} lg={3}>
                          <Index.Box className="dashboard-back-control">
                            <Index.Box className="copy-text-bg-main">
                              {" "}
                            </Index.Box>
                            <Index.Box
                              className="dashboard-box-cont"
                              onClick={() =>
                                navigate("/advertiser/advertisment-list")
                              }
                            >
                              <Index.Box className="admin-dashboard-box">
                                <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                  <Index.Box className="admin-dash-right">
                                    <img
                                      src={PageIndex.Png.totaladvertisement}
                                      // src={PageIndex.Png.flexo}
                                      className="dash-icons"
                                      alt="dashboard icon"
                                    />
                                    <Index.Tooltip
                                      title={
                                        allDashboardData?.totalAdvertisementsCount
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Index.Typography
                                        className="admin-dash-price"
                                        variant="h1"
                                        component="h1"
                                      >
                                        {
                                          allDashboardData?.totalAdvertisementsCount
                                        }
                                      </Index.Typography>
                                    </Index.Tooltip>
                                  </Index.Box>
                                  <Index.Box className="admin-dash-left">

                                    <Index.Typography
                                      className="admin-dash-text"
                                      variant="p"
                                      component="p"
                                    >
                                      Total Advertisement Created
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3} lg={3}>
                          <Index.Box className="dashboard-back-control">
                            <Index.Box className="copy-text-bg-main">
                              {" "}
                            </Index.Box>
                            <Index.Box
                              className="dashboard-box-cont"
                              onClick={() =>
                                navigate("/advertiser/advertisment-list", {
                                  state: { activeData: allDashboardData?.allActiveAdvertisements},
                                })
                              }
                            >
                              <Index.Box className="admin-dashboard-box">
                                <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                  <Index.Box className="admin-dash-right">
                                    <img
                                      src={PageIndex.Png.activeadvertisement}
                                      // src={PageIndex.Png.flexo}
                                      className="dash-icons"
                                      alt="dashboard icon"
                                    />
                                    <Index.Tooltip
                                      title={
                                        allDashboardData?.activeAdvertisementsCount
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Index.Typography
                                        className="admin-dash-price"
                                        variant="h1"
                                        component="h1"
                                      >
                                        {
                                          allDashboardData?.activeAdvertisementsCount
                                        }
                                      </Index.Typography>
                                    </Index.Tooltip>
                                  </Index.Box>
                                  <Index.Box className="admin-dash-left">

                                    <Index.Typography
                                      className="admin-dash-text"
                                      variant="p"
                                      component="p"
                                    >
                                      Active advertisement
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3} lg={3}>
                          <Index.Box className="dashboard-back-control">
                            <Index.Box className="copy-text-bg-main">
                              {" "}
                            </Index.Box>
                            <Index.Box
                              className="dashboard-box-cont"
                              onClick={() =>
                                navigate("/advertiser/advertiser-transaction")
                              }
                            >
                              <Index.Box className="admin-dashboard-box">
                                <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                  <Index.Box className="admin-dash-right">
                                    <img
                                      src={PageIndex.Png.Totalamountspent}
                                      // src={PageIndex.Png.flexo}
                                      className="dash-icons"
                                      alt="dashboard icon"
                                    />
                                    <Index.Tooltip
                                      title={allDashboardData?.totalAmountSpent?.toFixed(
                                        10
                                      )}
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Index.Typography
                                        className="admin-dash-price"
                                        variant="h1"
                                        component="h1"
                                      >
                                        {allDashboardData?.totalAmountSpent?.toFixed(
                                          10
                                        )}
                                      </Index.Typography>
                                    </Index.Tooltip>
                                  </Index.Box>
                                  <Index.Box className="admin-dash-left">

                                    <Index.Typography
                                      className="admin-dash-text"
                                      variant="p"
                                      component="p"
                                    >
                                      Total Amount Spent
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3} lg={3}>
                          <Index.Box className="dashboard-back-control">
                            <Index.Box className="copy-text-bg-main">
                              {" "}
                            </Index.Box>
                            <Index.Box
                              className="dashboard-box-cont"
                              onClick={() =>
                                navigate("/advertiser/advertiser-deposit-history")
                              }
                            >
                              <Index.Box className="admin-dashboard-box">
                                <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                  <Index.Box className="admin-dash-right">
                                    <img
                                      src={PageIndex.Png.Totalbalance}
                                      className="dash-icons"
                                      alt="dashboard icon"
                                    />

                                    <Index.Tooltip
                                      title={allDashboardData?.balance?.toFixed(
                                        10
                                      )}
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Index.Typography
                                        className="admin-dash-price"
                                        variant="h1"
                                        component="h1"
                                      >
                                        {allDashboardData?.balance?.toFixed(10)}
                                      </Index.Typography>
                                    </Index.Tooltip>
                                  </Index.Box>
                                  <Index.Box className="admin-dash-left">

                                    <Index.Typography
                                      className="admin-dash-text"
                                      variant="p"
                                      component="p"
                                    >
                                      Total Balance
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                {/* {allDashboardData?.activeTotalShownCountByDate?.length > 0 || allDashboardData?.totalShownCountByDate?.length > 0 && ( */}
                <Index.Box className="graph-box-cont graph-advertisement">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="flex-advertisemant-main">
                        <Index.Typography
                          className="graph-title"
                          component="h6"
                          variant="h6"
                        >
                          Advertisements
                        </Index.Typography>

                        <Index.Box className="filter-couston-advertisement form-control drop-form-control custom-drop-form-control">
                          <Index.Box className="main-select-day">
                            <Index.Select
                              className="dropdown-select drop-select dropdown-property custom-dropdown-property"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={advertisementFilter}
                              label="Age"
                              onChange={(e) => {
                                setAdvertisementFilter(e.target.value);
                                if (e.target.value !== 3) {
                                  handleFilterData(e.target.value);
                                }
                              }}
                            >
                              <Index.MenuItem value={0}>
                                This Year
                              </Index.MenuItem>
                              <Index.MenuItem value={7}>7 Days</Index.MenuItem>
                              <Index.MenuItem value={14}>
                                14 Days
                              </Index.MenuItem>
                              <Index.MenuItem
                                value={3}
                                onClick={handleOpenCustom}
                              >
                                Custom Filter Calender
                              </Index.MenuItem>
                            </Index.Select>
                            <Index.Box className="costom-select-calender-icon">
                              <CalendarMonthIcon />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {/* /dateSelecter */}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="graph-box">
                        <AdvertApexChart
                          dashbordData={allDashboardData}
                          filterValue={advertisementFilter}
                        />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                {/* // )} */}
              </Index.Grid>
            </Index.Grid>

            <Index.Box className="graph-box-cont">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={12} md={12} lg={12}>
                  <Index.Box className="graph-box">
                    <Index.Box className="dashboard-content user-list-content">
                      <Index.Box className="user-list-flex">
                        <Index.Typography
                          className="admin-page-title user-list-page-title main-heading"
                          component="h2"
                          variant="h2"
                        >
                          Transaction
                        </Index.Typography>

                        <Index.Box className="userlist-btn-flex res-flex">
                          <Index.Box className="header-date-picker add-user-input"></Index.Box>
                          <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box flex-search-details">
                              <Index.Box className="form-group search-box-bg">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    // Get the value from the input field
                                    let value = e.target.value;
                        
                                    // Remove any leading spaces
                                    value = value.replace(/^\s+/, '');
                        
                                    // Set the search state with the trimmed value
                                    setSearch(value);
                                  }}
                                  autoComplete="off"
                                  inputProps={{ pattern: "[^\s]*" }}
                                  value={stopWhiteSpace(search)}
                                />
                                <img
                                  src={PageIndex.Svg.search}
                                  className="search-grey-img"
                                  alt="search grey img"
                                ></img>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="table-bg-main table-main-box">
                        <Index.Box className="admin-dashboard-list-row">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              className="display-row"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-dash-box pb-0">
                                  <Index.Box className="advertiserlist-table-main transaction-table-main page-table-main">
                                    <Index.TableContainer
                                      component={Index.Paper}
                                      className="table-container"
                                    >
                                      <Index.Table
                                        aria-label="simple table"
                                        className="table"
                                      >
                                        <Index.TableHead className="table-head">
                                          <Index.TableRow className="table-row">
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Date
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Advertisement Title
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Network
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              From
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              To
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Hash
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Gasless
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Gasfess
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Total Amount($)
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        </Index.TableHead>
                                        <Index.TableBody className="table-body">
                                          {filteredData &&
                                            filteredData?.length > 0 ? (
                                            filteredData?.map((row, index) => (
                                              <Index.TableRow
                                                key={index}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                                }}
                                              >
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  {moment(
                                                    row?.createdAt
                                                  ).format("MMM D, YYYY")}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  {row?.advertisementId?.title
                                                    ? row?.advertisementId
                                                      ?.title
                                                    : "-"}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  -
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  scope="row"
                                                  className="table-td"
                                                >
                                                  <LightTooltip
                                                    title={row?.from}
                                                    arrow
                                                    placement="bottom"
                                                  >
                                                    <Index.Typography
                                                      className="meta-drop-title"
                                                      component="p"
                                                      variant="p"
                                                    >
                                                      {`${row?.from?.slice(
                                                        0,
                                                        7
                                                      )}....${row?.from?.slice(
                                                        -7
                                                      )}`}
                                                    </Index.Typography>
                                                  </LightTooltip>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <LightTooltip
                                                    title={row?.to}
                                                    arrow
                                                    placement="bottom"
                                                  >
                                                    <Index.Typography
                                                      className="meta-drop-title"
                                                      component="p"
                                                      variant="p"
                                                    >
                                                      {`${row?.to?.slice(
                                                        0,
                                                        7
                                                      )}....${row?.to?.slice(
                                                        -7
                                                      )}`}
                                                    </Index.Typography>
                                                  </LightTooltip>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  <LightTooltip
                                                    title={row?.transactionHash}
                                                    arrow
                                                    placement="bottom"
                                                  >
                                                    <Index.Typography
                                                      className="meta-drop-title"
                                                      component="p"
                                                      variant="p"
                                                    >
                                                      <a
                                                        href={row?.url}
                                                        target="_blank"
                                                        className="url-link"
                                                      >
                                                        {`${row?.transactionHash?.slice(
                                                          0,
                                                          7
                                                        )}....${row?.transactionHash?.slice(
                                                          -7
                                                        )}`}
                                                      </a>
                                                    </Index.Typography>
                                                  </LightTooltip>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  {row?.gasless == true
                                                    ? "True"
                                                    : "False"}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  {row?.gasFees}
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  className="table-td"
                                                >
                                                  {row?.value}
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            ))
                                          ) : loading ? (
                                            <SkeletonTable colSpan={9} />
                                          ) : (
                                            <Index.TableRow
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                              }}
                                            >
                                              <Index.TableCell
                                                component="td"
                                                variant="td"
                                                scope="row"
                                                className="table-td no-record-text"
                                                align="center"
                                                colSpan={9}
                                              >
                                                <h3> No records available </h3>
                                              </Index.TableCell>
                                            </Index.TableRow>
                                          )}
                                        </Index.TableBody>
                                      </Index.Table>
                                    </Index.TableContainer>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  {/* data show advertiment */}
                  <Index.Box className="graph-box">
                    <Index.Box className="dashboard-content user-list-content">
                      <Index.Box className="user-list-flex">
                        <Index.Typography
                          className="admin-page-title user-list-page-title main-heading"
                          component="h2"
                          variant="h2"
                        >
                          Advertisement History
                        </Index.Typography>

                        <Index.Box className="userlist-btn-flex res-flex">
                          <Index.Box className="header-date-picker add-user-input"></Index.Box>
                          {/* <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box flex-search-details">
                              <Index.Box className="form-group search-box-bg">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Search"
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                                <img
                                  src={PageIndex.Svg.search}
                                  className="search-grey-img"
                                  alt="search grey img"
                                ></img>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box> */}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="table-bg-main table-main-box">
                        <Index.Box className="admin-dashboard-list-row">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              className="display-row"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-dash-box pb-0">
                                  <Index.Box className="advertiserlist-table-main-costom transaction-table-main page-table-main">
                                    <Index.TableContainer
                                      component={Index.Paper}
                                      className="table-container"
                                    >
                                      <Index.Table
                                        aria-label="simple table"
                                        className="table"
                                      >
                                        <Index.TableHead className="table-head">
                                          <Index.TableRow className="table-row">
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Title
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Total Gas Fees
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Total Views
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Cost Per View
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              Start Date
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                            >
                                              End Date
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        </Index.TableHead>
                                        <Index.TableBody className="table-body">
                                          {advertismentHistoryData &&
                                            advertismentHistoryData?.length >
                                            0 ? (
                                            advertismentHistoryData?.map(
                                              (row, index) => (
                                                <Index.TableRow
                                                  key={index}
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  }}
                                                >
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {row?.title}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    <LightTooltip
                                                      arrow
                                                      placement="bottom"
                                                      title={row?.totalGasFees}
                                                    >
                                                      <Index.Typography
                                                        className="meta-drop-title"
                                                        component="p"
                                                        variant="p"
                                                      >
                                                        {row?.totalGasFees}
                                                      </Index.Typography>
                                                    </LightTooltip>
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {row?.totalViews}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {row?.costPerView}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {/* {row?.startDate} */}
                                                    {moment(
                                                      row?.startDate
                                                    ).format("MMM D, YYYY")}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {/* {row?.startDate} */}
                                                    {moment(
                                                      row?.endDate
                                                    ).format("MMM D, YYYY")}
                                                  </Index.TableCell>
                                                </Index.TableRow>
                                              )
                                            )
                                          ) : loading ? (
                                            <SkeletonTable colSpan={9} />
                                          ) : (
                                            <Index.TableRow
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                              }}
                                            >
                                              <Index.TableCell
                                                component="td"
                                                variant="td"
                                                scope="row"
                                                className="table-td no-record-text"
                                                align="center"
                                                colSpan={9}
                                              >
                                                <h3> No records available </h3>
                                              </Index.TableCell>
                                            </Index.TableRow>
                                          )}
                                        </Index.TableBody>
                                      </Index.Table>
                                    </Index.TableContainer>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={openDeposit}
            onClose={handleCloseDeposit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-auth modal-login modal-delete deposite-modal-main"
          >
            <Index.Box sx={style} className="delete-modal-inner-main">
              <Index.Box className="cancel-modal-btn">
                <Index.Button className="close-btn-modal">
                  <img
                    src={PageIndex.Png.close}
                    alt="close"
                    className="close-icon-modal"
                    onClick={handleCloseDeposit}
                  />
                </Index.Button>
              </Index.Box>
              <Index.Typography className="deposit-modal-heading modal-heading-deposit">
                Deposit
              </Index.Typography>
              <Index.Box>
                <PageIndex.Formik
                  initialValues={initialValues}
                  onSubmit={handleDeposit}
                  innerRef={(f) => (formRef.current = f)}
                  validationSchema={advertiserDepositSchema}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <PageIndex.Form onSubmit={handleSubmit}>
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Network
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box">
                          {console.log(
                            "values",
                            values,
                            errors,
                            depositAmountError
                          )}
                          <Index.Box className="form-control drop-form-control">
                            <Index.Select
                              name="network"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              value={values?.network}
                              className="dropdown-select drop-select dropdown-property"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(errors?.network)}
                              displayEmpty
                            >
                              <Index.MenuItem
                                value=""
                                disabled
                                className="drop-menuitem"
                              >
                                Select Network
                              </Index.MenuItem>

                              {allNetworksData?.map((network) => (
                                <Index.MenuItem
                                  value={network?.name}
                                  key={network?._id}
                                >
                                  {network?.name}
                                </Index.MenuItem>
                              ))}
                            </Index.Select>
                          </Index.Box>
                          {errors?.network && touched?.network ? (
                            <Index.Typography className="error-msg">
                              {errors?.network}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Amount
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            type="number"
                            name="depositAmount"
                            placeholder="Enter Amount"
                            value={values?.depositAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              setFieldValue(
                                "depositAmount",
                                inputValue.slice(0, 15)
                              );

                              const weiValue = inputValue * (10 ** 18);
                              if (weiValue > (walletBalance)) {
                                setDepositAmountError("Insufficient Balance");
                              } else {
                                // setDepositAmountError(null);
                                setDepositAmountError("");
                              }
                            }}
                            onBlur={handleBlur}
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                          {errors?.depositAmount || depositAmountError ? (
                            <Index.Typography className="error-msg">
                              {errors?.depositAmount || depositAmountError}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                      {console.log(
                        depositWaitingMessage,
                        "depositWaitingMessage"
                      )}

                      <Index.Typography className="error-msg">
                        {depositWaitingMessage}
                      </Index.Typography>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                          disabled={btnLoading}
                        >
                          {btnLoading ? (
                            <ButtonMvpLoader
                              color="white"
                              loading={btnLoading}
                            />
                          ) : (
                            <Index.Typography>Submit</Index.Typography>
                          )}
                        </Index.Button>
                      </Index.Box>
                    </PageIndex.Form>
                  )}
                </PageIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openCustom}
            onClose={handleCloseCustom}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete custom-modal-details"
          >
            <Index.Box sx={style} className="delete-modal-inner-main custom-modal-datepicker">
              <Index.Box>
                {advertisementFilter === 3 && (
                  <Index.Box>
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Custom Filter Calendar Start to End
                      </Index.FormHelperText>
                      <Index.LocalizationProvider
                        className="custom-range-picker-main"
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <DemoContainer
                          className="custom-range-picker"
                          components={["DateRangePicker"]}
                        >
                          <DateRangePicker
                            className="custom-range-picker-user"
                            // localeText={{ start: 'Check-in', end: 'Check-out' }}
                            // value={selectedDateRange ? dayjs(selectedDateRange)?.format('YYYY-MM-DD') :null}
                            value={selectedDateRange}
                            format="YYYY-MM-DD"
                            placeholder="YYYY-MM-DD"
                            onChange={handleDateChange}
                            disableLabel={true}
                          />
                        </DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                      <Index.Box className="custom-range-picker-button-main">
                      <Index.Button
                          className="custom-range-picker-button-close"
                          variant="outlined"
                          // color="secondary"
                          onClick={handleCloseCustom}
                          // style={{ marginRight: "8px" }}
                      >
                          Close
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </>
      )}
    </>
  );
}
