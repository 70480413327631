// import video from './images/video/video.mp4';








const Video = {
    // loginbg,
    // video
    
}

export default Video;