import React from "react";
import DataService from "../config/DataService";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminPrivateRoutes = () => {
  const { token } = useSelector((state) => state.admin);
  const isAuthenticate = (token) => {
    if (!token) return false;
    DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAuthenticate(token) ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/login" replace={true} />
  );
};

export default AdminPrivateRoutes;
