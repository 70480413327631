import React from "react";

function categories() {
  return (
    <svg
      id="e73e2821-510d-456e-b3bd-9363037e93e3"
      width="30"
      height="20"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
    >
      <path d="m11.933 15.055h-8.454a3.232 3.232 0 0 1 -3.229-3.228v-8.349a3.232 3.232 0 0 1 3.229-3.228h8.454a3.232 3.232 0 0 1 3.228 3.228v8.349a3.232 3.232 0 0 1 -3.228 3.228zm-8.454-12.305a.73.73 0 0 0 -.729.728v8.349a.73.73 0 0 0 .729.728h8.454a.729.729 0 0 0 .728-.728v-8.349a.729.729 0 0 0 -.728-.728z"  fill="#fff"/>
      <path d="m11.974 34.75h-8.454a3.233 3.233 0 0 1 -3.229-3.229v-8.348a3.232 3.232 0 0 1 3.229-3.228h8.454a3.232 3.232 0 0 1 3.226 3.228v8.348a3.232 3.232 0 0 1 -3.226 3.229zm-8.454-12.305a.73.73 0 0 0 -.729.728v8.348a.73.73 0 0 0 .729.729h8.454a.73.73 0 0 0 .728-.729v-8.348a.729.729 0 0 0 -.728-.728z"  fill="#fff"/>
      <path d="m31.522 34.75h-8.454a3.233 3.233 0 0 1 -3.229-3.229v-8.348a3.232 3.232 0 0 1 3.229-3.228h8.454a3.232 3.232 0 0 1 3.228 3.228v8.348a3.232 3.232 0 0 1 -3.228 3.229zm-8.454-12.3a.73.73 0 0 0 -.729.728v8.348a.73.73 0 0 0 .729.729h8.454a.73.73 0 0 0 .728-.729v-8.353a.729.729 0 0 0 -.728-.728z"  fill="#fff" />
      <path
        d="m27.3 15.055a7.4 7.4 0 1 1 7.455-7.4 7.437 7.437 0 0 1 -7.455 7.4zm0-12.3a4.9 4.9 0 1 0 4.955 4.9 4.935 4.935 0 0 0 -4.955-4.905z"
        fill="#fff"
      />
    </svg>
  );
}

export default categories;
