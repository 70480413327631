import React, { useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { useDispatch } from "react-redux";
import { updateAdvertiserAmount } from "../../../../redux/slices/AdminService";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { updateAdvertiserSchema } from "../../../../validations/Validation";

function UpdateAdvertiser() {
  const navigate = PageIndex?.useNavigate();
  const params = PageIndex.useLocation();
  const [isDisabled, setIsDisabled] = useState(false);
  const advertiserSingleData = params?.state;

  const initialValues = {
    walletAdress: advertiserSingleData?._id
      ? ''
      // advertiserSingleData?.wallets[0]?.smartAccountAddress
      : "",
    walletAmount: advertiserSingleData?._id
      ? advertiserSingleData?.walletAmount
      : ""
  };

  const dispatch = useDispatch();
  const handleFormSubmit = (values, { resetForm }) => {
    setIsDisabled(true);
    const amountData = {
      walletAmount: +values.walletAmount,
      id: advertiserSingleData?._id
    };

    dispatch(updateAdvertiserAmount(amountData)).then((data) => {
      setTimeout(() => {
        setIsDisabled(false);
      }, 6000);
      if (data?.payload?.status === 200 || data?.payload?.status === 201) {
        setIsDisabled(false);
        navigate("/admin/advertiser-list");
        resetForm({
          ...values,
          publisherCommission: ""
        });
      }
    });
  };

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        Edit Advertiser
      </Index.Typography>
      <Index.Box className="table-bg-main">
        <PageIndex.Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={updateAdvertiserSchema}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, errors }) => (
            <PageIndex.Form onSubmit={handleSubmit}>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Wallet Address<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            disabled
                            name="walletAdress"
                            as={Index.TextField}
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (inputValue.length <= 10) {
                                // Only update the value if it's not more than 30 characters
                                handleChange(event);
                              }
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Wallet<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            name="walletAmount"
                            // as={Index.TextField}
                            value={values?.walletAmount}
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            type="number"
                            placeholder="Please enter wallet"
                            onWheel={(e) => e.target.blur()}
                            onKeyPress={(event) => {
                              if (event.key === "+" || event.key === "-") {
                                event.preventDefault();
                              }
                            }}
                            onChange={(event) => {
                              let inputValue = event.target.value;
                              // Remove special characters
                              inputValue = inputValue.replace(/[^0-9.]/g, "");

                              if (inputValue?.length <= 5) {
                                handleChange(event);
                                setFieldValue("walletAmount", inputValue);
                              }
                            }}
                          />
                        </Index.Box>
                        {errors?.walletAmount ? (
                          <Index.Typography className="error-msg">
                            {errors?.walletAmount}
                          </Index.Typography>
                        ) : null}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="discard-btn-main border-btn-main">
                          <Index.Button
                            className="discard-user-btn border-btn"
                            onClick={() => navigate("/admin/advertiser-list")}
                          >
                            Back
                          </Index.Button>
                        </Index.Box>
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                            disabled={isDisabled}
                          >
                            {isDisabled ? (
                              <ButtonMvpLoader
                                color="white"
                                loading={isDisabled}
                              />
                            ) : (
                              <>
                                <img
                                  alt="imgcategory"
                                  src={PageIndex.Svg.save}
                                  className="user-save-icon"
                                ></img>
                                Update
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
}

export default UpdateAdvertiser;
