import React from "react";

function deposit() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="fi_2626618"
        data-name="Layer 1"
        viewBox="0 0 64 64"
        width="30"
        height="20"
      >
        <path
          d="M33,28H27a2,2,0,0,0,0,4h3a2,2,0,0,0,4,0v-.09A6,6,0,0,0,33,20H31a2,2,0,0,1,0-4h6a2,2,0,0,0,0-4H34a2,2,0,0,0-4,0v.09A6,6,0,0,0,31,24h2a2,2,0,0,1,0,4Z"
          fill="#fff"
        ></path>
        <path
          d="M58,20H51.9a20,20,0,0,0-39.8,0H6a6,6,0,0,0-6,6V56a6,6,0,0,0,6,6H58a6,6,0,0,0,6-6V26A6,6,0,0,0,58,20ZM49.29,32A2,2,0,0,1,51,34a2,2,0,0,1-2,2H46.27A20.11,20.11,0,0,0,49.29,32ZM32,6A16,16,0,0,1,48,22a15.81,15.81,0,0,1-1.67,7.11A16,16,0,0,1,39.72,36H24.28a16,16,0,0,1-6.61-6.89A15.81,15.81,0,0,1,16,22,16,16,0,0,1,32,6ZM14.71,32a20.11,20.11,0,0,0,3,4H15a2,2,0,0,1-2-2,2,2,0,0,1,.57-1.41A2.05,2.05,0,0,1,14.71,32ZM60,56a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V26a2,2,0,0,1,2-2h6.1A19.55,19.55,0,0,0,13,28.34,6,6,0,0,0,9,34a6,6,0,0,0,6,6H49a6,6,0,0,0,2-11.66A19.55,19.55,0,0,0,51.9,24H58a2,2,0,0,1,2,2Z"
          fill="#fff"
        ></path>
        <circle cx="54" cy="52" r="2"></circle>
        <circle cx="46" cy="52" r="2"></circle>
      </svg>
    </>
  );
}

export default deposit;
