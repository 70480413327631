import Index from "../../Index";
import React from "react";
const DeleteModal = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Index.Modal
      open={props.deleteOpen}
      onClose={props.handleDeleteClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete"
    >
      <Index.Box sx={style} className="delete-modal-inner-main">
        <Index.Box>
          <Index.Box>
            <h3 className="deleteModel-heading">
              Are you sure you want to delete {props?.handleModalName}?
            </h3>
          </Index.Box>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={props.handleDeleteClose}
            >
              Cancel
            </Index.Button>
            <Index.Box className="btn-main-primary">
            <Index.Button className="btn-primary "
              onClick={props.handleDeleteRecord}
            >
              Delete
            </Index.Button>
          </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};

export default DeleteModal;
