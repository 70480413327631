import usericon from "./images/png/user.png";
import logo from "./images/png/logo.png";
import edit from "./images/png/edit.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import close from "./images/png/close.png";
import user1 from "./images/png/user-1.png";
import userimg from "./images/png/user-dashboard.png";
import flexoLogo from "./images/png/flexo_logo.png";
import favIconLogo from "./images/png/image_logo.png";
import metaLogo from "./images/png/metaLogo.png";
import flexo from "./images/png/flexo.png";
import user2 from "./images/png/user2.png";
import dashboardBackground from "./images/png/Dashboardbackground.png"
import back from "./images/png/back.png"
import userbg from "./images/png/userbg.png"
import filterwhite from "./images/png/filterwhite.png"
import active from "./images/png/active.png"
import activeadvertisement from "./images/png/activeadvertisement.png"
import buybackwallet from "./images/png/buybackwallet.png"
import companywallet from "./images/png/companywallet.png"
import gasfeewallet from "./images/png/gasfeewallet.png"
import Revenue from "./images/png/Revenue.png"
import sellproductsonline from "./images/png/sell-products-online.png"
import sponsoredgasfees from "./images/png/sponsoredgasfees.png"
import totaladvertisement from "./images/png/totaladvertisement.png"
import totaladvertisers from "./images/png/totaladvertisers.png"
import walletusers from "./images/png/walletusers.png"
import Totalamountspent from "./images/png/Totalamountspent.png"
import Totalbalance from "./images/png/Totalbalance.png"
import Totalcategories from "./images/png/Totalcategories.png"


const Png = {
  dashboardBackground,
  favIconLogo,
  back,
  userimg,
  usericon,
  logo,
  edit,
  userlist1,
  bergurmenu,
  close,
  user1,
  flexoLogo,
  metaLogo,
  flexo,
  user2,
  userbg,
  filterwhite,
  active,
  activeadvertisement,
  Totalcategories,
  Totalbalance,
  Totalamountspent,
  walletusers,
  totaladvertisers,
  totaladvertisement,
  sponsoredgasfees,
  sellproductsonline,
  Revenue,
  gasfeewallet,
  companywallet,
  buybackwallet
};

export default Png;
