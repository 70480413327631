import Svg from "../assets/Svg";
import Png from "../assets/Png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MvpLoader from "./common/mvpLoader/MvpLoader";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema, forgotPasswordSchema, verifyOtpSchema } from "../validations/Validation";

export default {
  useSelector,
  MvpLoader,
  useNavigate,
  useLocation,
  Svg,
  Png,
  useDispatch,
  loginSchema,
  forgotPasswordSchema,
  verifyOtpSchema,
};
