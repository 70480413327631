
import Routes from "./routes/Routes";
import "./components/admin/defaultLayout/defaultlayout.css";
import "./components/admin/defaultLayout/defaultlayout.responsive.css";
import "./assets/style/global.css";
import "./container/admin/auth/auth.css";
import "./container/admin/auth/auth.responsive.css";
import "./container/admin/pages/layout/layout.css";
import "./container/admin/pages/layout/layout.responsive.css";
import "./container/admin/pages/home/home.css";
import "./container/admin/pages/home/home.responsive.css";
import "./container/admin/pages/userManagementList/usermanagement.css";
import "./container/admin/pages/userManagementList/usermanagement.responsive.css";
import "./container/admin/pages/advertiser/advertiser.css";
import "./container/admin/pages/advertiser/advertiser.responsive.css";
import "./container/admin/pages/editprofile/editprofile.css";
import "./container/admin/pages/editprofile/editprofile.responsive.css";
import "./assets/style/global.responsive.css";
import "./container/advertiser/pages/advertisereditprofile/advertisereditprofile.css"
import "./assets/style/userglobal.css";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'



const projectId = '2d7ded4b2b1becfe9064b344d8c4ba63'
const mainnet = {
  chainId: 11155111,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com'
}

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}


const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
})

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


function App() {
  return <Routes />;
}

export default App;
