import React from "react";

function advertiser() {
  return (
    <svg
      id="Layer_1"
      width="30"
      height="20"
      viewBox="0 0 497.904 497.904"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m485.584 338.959-156.597-271.235c-35.303-61.149-124.923-60.878-160.071 0l-156.598 271.235c-35.212 60.988 9.402 136.981 78.915 136.981 31.226 0 62.08-16.013 79.212-45.687l78.506-135.977 78.506 135.977c17.105 29.628 47.937 45.687 79.212 45.687 69.466 0 114.146-75.96 78.915-136.981zm-342.852 75.294c-13.822 23.942-41.921 34.781-67.794 27.341-37.155-10.795-54.038-53.502-34.908-86.636 16.384-28.379 52.612-38.092 80.998-21.704 28.381 16.386 38.092 52.615 21.704 80.999zm34.696-60.094c-14.906-42.335-58.535-67.563-102.701-59.294l88.413-153.135c3.939 12.044 1.172 5.946 67.337 120.547zm258.74 81.798c-28.377 16.386-64.611 6.68-80.998-21.704l-156.597-271.235c-29.882-51.757 27.723-110.637 79.054-80.998 19.208 11.09 13.923 4.861 180.245 292.938 16.347 28.315 6.611 64.651-21.704 80.999z"
        fill="#fff"
      />
    </svg>
  );
}

export default advertiser;
