import React from "react";

function advertisement() {
  return (
    <svg
      id="Layer_1"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
        width="27"
      height="27"
    >
      <path d="m40.041 2.555c-.916-.612-2.068-.723-3.084-.3l-18.418 7.666h-6.526c-4.26 0-7.726 3.466-7.726 7.726 0 4.015 3.08 7.322 7.002 7.689l1.351 9.831c.151 1.124.729 2.119 1.627 2.803.742.564 1.63.862 2.552.862.196 0 .393-.014.59-.041 2.31-.321 3.931-2.462 3.613-4.771l-1.104-8.073 17.039 7.091c.407.169.836.253 1.263.253.639 0 1.272-.187 1.821-.553.916-.61 1.462-1.632 1.462-2.732v-24.719c0-1.1-.546-2.122-1.462-2.732zm-33.754 15.091c0-3.157 2.568-5.726 5.726-5.726h5.726v11.452h-5.726c-3.157 0-5.726-2.568-5.726-5.726zm10.848 19.163c-.597.08-1.185-.072-1.657-.432-.472-.359-.776-.884-.856-1.48l-1.309-9.525h4.509l1.219 8.918c.167 1.22-.688 2.35-1.906 2.519zm22.369-6.804c0 .437-.208.826-.572 1.068-.364.243-.803.286-1.206.117l-17.987-7.486v-12.117l17.987-7.486c.403-.17.843-.125 1.206.117s.572.632.572 1.068z"  fill="#fff" />
      <path d="m45.032 10.857c.094 0 .189-.014.284-.042l7.284-2.15c.53-.156.832-.712.676-1.242-.157-.53-.712-.832-1.242-.676l-7.284 2.15c-.53.156-.832.712-.676 1.242.128.435.527.717.958.717z"  fill="#fff"/>
      <path d="m45.033 18.646h7.284c.552 0 1-.448 1-1s-.448-1-1-1h-7.284c-.552 0-1 .448-1 1s.448 1 1 1z"  fill="#fff" />
      <path d="m52.6 26.626-7.284-2.15c-.529-.155-1.086.146-1.242.676s.146 1.086.676 1.242l7.284 2.15c.095.028.19.042.284.042.432 0 .83-.282.958-.717.156-.53-.146-1.086-.676-1.242z"  fill="#fff" />
      <path d="m25.009 38.672v19.897c0 1.892 1.539 3.43 3.43 3.43h27.844c1.892 0 3.431-1.539 3.431-3.43v-19.897c0-1.892-1.539-3.431-3.431-3.431h-27.844c-1.892 0-3.43 1.539-3.43 3.431zm32.705 0v19.897c0 .789-.642 1.43-1.431 1.43h-27.844c-.789 0-1.43-.642-1.43-1.43v-19.897c0-.789.642-1.431 1.43-1.431h27.844c.789 0 1.431.642 1.431 1.431z"  fill="#fff"/>
      <path
        d="m38.382 55.527c.317.175.667.263 1.017.263.388 0 .776-.107 1.117-.321l8.079-5.066c.617-.387.984-1.053.984-1.781s-.368-1.394-.984-1.781l-8.079-5.066c-.648-.407-1.466-.43-2.134-.059-.669.37-1.084 1.075-1.084 1.839v10.132c0 .765.416 1.47 1.085 1.84zm.915-11.972c0-.02 0-.061.053-.089.02-.011.036-.015.051-.015.024 0 .042.011.053.018l8.079 5.066.531-.847-.531 1.021-8.079 5.066c-.017.01-.052.032-.104.003-.053-.029-.053-.072-.053-.09v-10.132z"
        fill="#fff"
      />
    </svg>
  );
}

export default advertisement;
