import React, { useState } from "react";
import PropTypes from "prop-types";
import Index from "../../../Index";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  changeAdminPassword,
  updateAdminData
} from "../../../../redux/slices/AdminService";
import { useNavigate } from "react-router-dom";
import { imageUrl } from "../../../../config/DataService";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import PageIndex from "../../../PageIndex";
import {
  ChangepasswordSchema,
  EditProfileSchema
} from "../../../../validations/Validation";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { logout } from "../../../../redux/slices/AdminSlice";

// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function EditProfile() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { adminData } = useSelector((state) => state.admin);
  console.log(adminData,"adminData")

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const initialProfileValue = {
    name: adminData?.name,
    email: adminData?.email,
    mobileNumber: adminData?.mobileNumber,
    adminWallet:adminData? adminData?.adminWallet : "",
    publisherWallet: adminData ? adminData?.publisherWallet : "",
    buyBackWallet: adminData ? adminData?.buyBackWallet : ""
  };
  const handleClickShowPassword = (field) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword((show) => !show);
        break;
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "confirmPassword":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //edit profile call
  const handleEditProfile = (values) => {
    setLoading(true);
    const formdata = new FormData();
    // formdata.append("id", adminData._id);
    for (let key in values) {
      formdata.append(key, values[key]);
    }
    dispatch(updateAdminData(formdata)).then((res) => {
      if (res?.payload?.status === 200) {
        console.log(res?.payload?.data, "editedData")
        const editedData=res?.payload?.data
        if (editedData?.email && editedData.email !== adminData?.email){
          dispatch(logout());
          navigate("/admin/login");
        }
      }
      setLoading(false);
    });
  };
  const submitForm = async (values, { resetForm }) => {
    setLoading(true);
    const passwordData = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword
    };

    try {
      const response = await dispatch(changeAdminPassword(passwordData));
      if (response?.payload?.status === 200 || response?.payload?.status === 201) {
        resetForm({
          ...values,
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        });
        // Display toaster message here
        // toaster.success("Password changed successfully!");
      }
    } catch (error) {
      // toaster.error("Error changing password!");
      console.log(error)
    } finally {
      dispatch(logout());
      navigate("/admin/login");
    }
 
  };
  
  return (
    <>
      {loading ? (
        <MvpLoader color="#1B698E" loading={loading} />
      ) : (
        <Index.Box className="dashboard-content edit-profile-containt">
          <Index.Typography
            className="admin-page-title main-heading"
            component="h2"
            variant="h2"
          >
            Account Setting
          </Index.Typography>

          <Index.Box className="tabs-main-box table-bg-main">
            <Index.Box sx={{ width: "100%" }}>
              <Index.Box sx={{ borderBottom: 1, borderColor: "#7a779c" }}>
                <Index.Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="admin-tabs-main"
                >
                  <Index.Tab
                    label="Edit Profile"
                    {...a11yProps(0)}
                    className="admin-tab"
                  />
                  <Index.Tab
                    label="Change Password"
                    {...a11yProps(1)}
                    className="admin-tab"
                  />
                </Index.Tabs>
              </Index.Box>

              <Formik
                initialValues={initialProfileValue}
                onSubmit={handleEditProfile}
                validationSchema={EditProfileSchema}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    {console.log(props?.values ,"valuesssss")}
                    <TabPanel
                      value={value}
                      index={0}
                      className="admin-tabpanel"
                    >
                      <Index.Box className="tabpanel-main">
                        <Index.Box className="">
                          {/* <Index.Box className="page-border"> */}
                          <Index.Typography
                            className="common-para edit-highlight-text"
                            component="p"
                            variant="p"
                          ></Index.Typography>
                          <Index.Typography
                            className="common-para edit-para-text"
                            component="p"
                            variant="p"
                          ></Index.Typography>
                          <Index.Box className="edit-profile-flex">
                            <Index.Box className="file-upload-btn-main">
                              <img
                                  src={
                                    props?.values?.file && props?.values?.file instanceof File
                                      ? URL.createObjectURL(props?.values?.file)
                                      : adminData?.image === "false"
                                        ? Index.Png.usericon
                                        : `${imageUrl}/${adminData?.image}`
                                  }
                                onChange={(e) => e.target.files[0]}
                                className="upload-profile-img"
                                alt="upload img"
                              ></img>
                              <Index.Button
                                variant="contained"
                                component="label"
                                className="file-upload-btn"
                              >
                                <img
                                  // src={Index.Svg.edit}
                                  className="upload-icon-img"
                                  alt="upload img"
                                ></img>
                                <input
                                  hidden
                                  accept="image/*"
                                  name="image"
                                  type="file"
                                  onChange={(e) => {
                                    try {
                                      props.setFieldValue(
                                        "file",
                                        e.target.files[0]
                                      );
                                    } catch (error) {
                                      console.error(error);
                                      e.target.value = null;
                                    }
                                  }}
                                />
                              </Index.Button>
                            </Index.Box>
                              <Index.FormHelperText className="error-text-custom">
                                {props?.errors?.file}
                              </Index.FormHelperText>
                          </Index.Box>
                          <Index.Box className="add-user-data-main">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                className="display-row-edit-profile"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6"
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                      Name<span>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        fullWidth
                                        name="name"
                                        placeholder="Please enter name"
                                        value={props.values.name}
                                        onKeyDown={(e) => {
                                          if (e.key === " " && e.target.value.trim() === "") {
                                            e.preventDefault(); // Prevent space as the first character
                                          } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                                            e.preventDefault(); // Prevent adding additional spaces between words
                                          } else if (e.key === " " && e.target.value.endsWith(" ")) {
                                            e.preventDefault(); // Prevent additional spaces at the end
                                          }
                                          // if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                          //   e.preventDefault(); // Prevent non-numeric characters
                                          // }
                                        }}
                                        onChange={(e) => {
                                          let value = e.target.value;

                                          // Remove any leading spaces
                                          value = value.trimStart();

                                          // Replace multiple spaces with a single space
                                          value = value.replace(/\s\s+/g, " ");

                                          const regex = /^[A-Za-z][A-Za-z\s]*$/;

                                          if (
                                            regex.test(value) ||
                                            value === ""
                                          ) {
                                            // Limit the length of the name to 20 characters
                                            props.setFieldValue(
                                              "name",
                                              value.slice(0, 32)
                                            );
                                          }
                                        }}
                                        maxLength={32}
                                        helperText={props.errors.name}
                                        error={Boolean(props.errors.name)}
                                        id="fullWidth"
                                        className="form-control"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6"
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                      Email<span>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        name="email"
                                        value={props.values.email}
                                        // onChange={(event) => {
                                        //   const inputValue = event.target.value;
                                        //   if (inputValue.length <= 30) {
                                        //     // Only update the value if it's not more than 30 characters
                                        //     props.handleChange(event);
                                        //   }
                                        // }}
                                        onChange={(e) => {
                                          const inputValue =
                                            e.target.value.trim(); // Trim whitespace
                                          if (inputValue.length <= 50) {
                                            // Check if not empty after trimming
                                            props.setFieldValue(
                                              "email",
                                              inputValue.slice(0, 50)
                                            );
                                          }
                                        }}
                                        maxLength={50}
                                        helperText={props.errors.email}
                                        error={Boolean(props.errors.email)}
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder="Please enter email"
                                        onKeyDown={(e) =>
                                          e.keyCode === 32 && e.preventDefault()
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6"
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                      Mobile Number<span>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="form-control"
                                        placeholder="Please enter mobile number"
                                        name="mobileNumber"
                                        value={props.values.mobileNumber}
                                        onChange={(event) => {
                                          const inputValue = event.target.value;
                                          if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
                                            // Only update the value if it's not more than 10 characters
                                            props.handleChange(event);
                                          }
                                        }}
                                        helperText={props.errors.mobileNumber}
                                        error={Boolean(
                                          props.errors.mobileNumber
                                        )}
                                        maxLength={10}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6"
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                      Admin Wallet(%)<span>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        name="adminWallet"
                                        // as={Index.TextField}
                                        value={props?.values?.adminWallet}
                                        // label="Carpet Area"
                                        variant="outlined"
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter admin wallet"
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(event) => {
                                          let inputValue = event.target.value;
                                          inputValue = inputValue.replace(
                                            /\s\s+/g,
                                            " "
                                          );

                                          if (inputValue?.length <= 5) {
                                            // Only update the value if it's not more than 30 characters
                                            props.handleChange(event);
                                          }
                                        }}
                                      />
                                    </Index.Box>
                                    {props?.errors?.adminWallet ? (
                                      <Index.Typography className="error-msg">
                                        {props?.errors?.adminWallet}
                                      </Index.Typography>
                                    ) : null}
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6"
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                      Publisher Wallet(%)
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        name="publisherWallet"
                                        // as={Index.TextField}
                                        value={props?.values?.publisherWallet}
                                        // label="Carpet Area"
                                        variant="outlined"
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter publisher  wallet"
                                        onWheel={(e) => e.target.blur()}
                                        inputProps={{ maxLength: 5 }}
                                        onChange={(event) => {
                                          let inputValue = event.target.value;
                                          inputValue = inputValue.replace(
                                            /\s\s+/g,
                                            " "
                                          );

                                          if (inputValue?.length <= 5) {
                                            // Only update the value if it's not more than 30 characters
                                            props.handleChange(event);
                                          }
                                        }}
                                      />
                                    </Index.Box>
                                    {/* {props?.errors?.publisherWallet ? (
                                      <Index.Typography className="error-msg">
                                        {props?.errors?.publisherWallet}
                                      </Index.Typography>
                                    ) : null} */}
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6"
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box add-user-input">
                                    <Index.FormHelperText className="form-lable">
                                      Buy Back Wallet(%)<span>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        name="buyBackWallet"
                                        // as={Index.TextField}
                                        value={props?.values?.buyBackWallet}
                                        // label="Carpet Area"
                                        variant="outlined"
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        type="text"
                                        placeholder="Please enter buy back wallet"
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(event) => {
                                          let inputValue = event.target.value;
                                          inputValue = inputValue.replace(
                                            /\s\s+/g,
                                            " "
                                          );

                                          if (inputValue?.length <= 5) {
                                            // Only update the value if it's not more than 30 characters
                                            props.handleChange(event);
                                          }
                                        }}
                                      />
                                    </Index.Box>
                                    {props?.errors?.buyBackWallet ? (
                                      <Index.Typography className="error-msg">
                                        {props?.errors?.buyBackWallet}
                                      </Index.Typography>
                                    ) : null}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12"
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="user-btn-flex">
                                  <Index.Box className="discard-btn-main border-btn-main">
                                    <Index.Button
                                      className="discard-user-btn border-btn"
                                      onClick={() =>
                                        navigate("/admin/dashboard")
                                      }
                                    >
                                      Discard
                                    </Index.Button>
                                  </Index.Box>
                                  <Index.Box className="save-btn-main border-btn-main">
                                    <Index.Button
                                      className="save-user-btn border-btn"
                                      type="submit"
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <ButtonMvpLoader
                                          color="#102934"
                                          loading={loading}
                                        />
                                      ) : (
                                        <>
                                          <img
                                            alt="imgcategory"
                                            src={PageIndex.Svg.save}
                                            className="user-save-icon"
                                          ></img>
                                          Update
                                        </>
                                      )}
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </TabPanel>
                  </form>
                )}
              </Formik>

              <TabPanel value={value} index={1} className="admin-tabpanel">
                <Index.Box className="tabpanel-main">
                  <Index.Box className="change-pass-main">
                    <Formik
                      initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: ""
                      }}
                      onSubmit={submitForm}
                      validationSchema={ChangepasswordSchema}
                    >
                      {({
                        handleBlur,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                        isValid,
                        dirty
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Index.Box className="input-box change-pass-input-box">
                            <Index.FormHelperText className="form-lable">
                              Old Password<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group position-relative">
                              <Index.TextField
                                fullWidth
                                size="small"
                                placeholder="Please enter old password"
                                name="oldPassword"
                                className="form-control input-with-radius password-form-control"
                                type={showOldPassword ? "text" : "password"}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props"
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props"
                                }}
                                onKeyDown={(e) =>
                                  e.keyCode === 32 && e.preventDefault()
                                }
                                onBlur={handleBlur}
                                value={values.oldPassword}
                                //  onChange={(event) => {
                                //           const inputValue = event.target.value;
                                //           if (inputValue.length <= 8) {
                                //             // Only update the value if it's not more than 30 characters
                                //          handleChange(event);
                                //           }
                                //         }}
                                onChange={(e) => {
                                  const inputValue = e.target.value.trim(); // Trim whitespace
                                  if (inputValue.length >= 0) {
                                    // Check if not empty after trimming
                                    setFieldValue(
                                      "oldPassword",
                                      inputValue.slice(0, 25)
                                    );
                                  }
                                }}
                                maxLength={8}
                                // helperText={errors.oldPassword}
                                // error={Boolean(errors.oldPassword)}

                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        className="passwrd-eye"
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          handleClickShowPassword("oldPassword")
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showOldPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  )
                                }}
                              />

                              {errors?.oldPassword && touched?.oldPassword ? (
                                <Index.Typography
                                  className="error-msg-profile"
                                  // style={{ marginTop: "-21px" }}
                                >
                                  {errors?.oldPassword}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="input-box change-pass-input-box">
                            <Index.FormHelperText className="form-lable">
                              New Password<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                size="small"
                                name="newPassword"
                                placeholder="Please enter new password"
                                className="form-control input-with-radius password-form-control"
                                type={showNewPassword ? "text" : "password"}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props"
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props"
                                }}
                                onBlur={handleBlur}
                                value={values.newPassword}
                                onChange={(e) => {
                                  const inputValue = e.target.value.trim(); // Trim whitespace
                                  if (inputValue.length >= 0) {
                                    // Check if not empty after trimming
                                    setFieldValue(
                                      "newPassword",
                                      inputValue.slice(0, 25)
                                    );
                                  }
                                }}
                                onKeyDown={(e) =>
                                  e.keyCode === 32 && e.preventDefault()
                                }
                                // helperText={errors.newPassword}
                                // error={Boolean(errors.newPassword)}

                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        className="passwrd-eye"
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          handleClickShowPassword("newPassword")
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showNewPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  )
                                }}
                              />
                              {errors?.newPassword && touched?.newPassword ? (
                                <Index.Typography
                                  className="error-msg-profile"
                                  // style={{ marginTop: "-21px" }}
                                >
                                  {errors?.newPassword}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="input-box change-pass-input-box">
                            <Index.FormHelperText className="form-lable">
                              Confirm Password<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                size="small"
                                name="confirmPassword"
                                placeholder="Please enter confirm password"
                                className="form-control input-with-radius password-form-control"
                                type={showConfirmPassword ? "text" : "password"}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props"
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props"
                                }}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                onKeyDown={(e) =>
                                  e.keyCode === 32 && e.preventDefault()
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value.trim(); // Trim whitespace
                                  if (inputValue.length >= 0) {
                                    // Check if not empty after trimming
                                    setFieldValue(
                                      "confirmPassword",
                                      inputValue.slice(0, 25)
                                    );
                                  }
                                }}
                                // helperText={errors.confirmPassword}
                                // error={Boolean(errors.confirmPassword)}

                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        className="passwrd-eye"
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          handleClickShowPassword(
                                            "confirmPassword"
                                          )
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  )
                                }}
                              />
                            </Index.Box>
                            {errors?.confirmPassword &&
                            touched?.confirmPassword ? (
                              <Index.Typography
                                className="error-msg-profile"
                                // style={{ marginTop: "-21px" }}
                              >
                                {errors?.confirmPassword}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12"
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-btn-flex">
                              <Index.Box className="discard-btn-main border-btn-main">
                                <Index.Button
                                  className="discard-user-btn border-btn"
                                  onClick={() => navigate("/admin/dashboard")}
                                >
                                  Discard
                                </Index.Button>
                              </Index.Box>
                              <Index.Box className="save-btn-main border-btn-main">
                                <Index.Button
                                  className="save-user-btn border-btn"
                                  type="submit"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <ButtonMvpLoader
                                      color="#102934"
                                      loading={loading}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        alt="imgcategory"
                                        src={PageIndex.Svg.save}
                                        className="user-save-icon"
                                      ></img>
                                      Update
                                    </>
                                  )}
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </form>
                      )}
                    </Formik>
                  </Index.Box>
                </Index.Box>
              </TabPanel>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
