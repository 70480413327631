import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import {
  createCategoryData,
  updateCategoryData
} from "../../../../redux/slices/AdminService";
import { imageUrl } from "../../../../config/DataService";
import { categorySchema } from "../../../../validations/Validation";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";

export default function AddUpdateCategory() {
  // for open handleChangedropdown
  const [imageData, setImageData] = useState([]);

  const [imageDataNew, setImageDataNew] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState("");
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();

  const singleCategoryData = params?.state;
  console.log(singleCategoryData, "singleCategoryData");

  const initialValues = {
    Category_Name: singleCategoryData?._id
      ? singleCategoryData?.Category_Name
      : "",
    Category_Image: singleCategoryData?.Category_Image
      ? singleCategoryData?.Category_Image
      : "",
    Category_Type: singleCategoryData?._id
      ? singleCategoryData?.categoryType
      : "Top",
    Category_Number: singleCategoryData?._id
      ? singleCategoryData?.categoryNumber
      : ""
  };

  const handleCategorySubmit = (values) => {
    setLoading(true);

    const formData = new FormData();
    if (values?.Category_Type == "Top") {
      formData.append(
        "Category_Name",
        values?.Category_Type + " " + values?.Category_Number
      );
      formData.append("categoryNumber", values?.Category_Number);
    }
    if (values?.Category_Type == "Other") {
      formData.append("Category_Name", values.Category_Name);
    }

    formData.append("file", values?.Category_Image || "");
    formData.append("categoryType", values?.Category_Type);

    // formData.append("file", imageDataNew);

    if (singleCategoryData?._id) {
      formData.append("id", singleCategoryData?._id);
    }
    !singleCategoryData?._id
      ? dispatch(createCategoryData(formData)).then((val) => {
          if (val?.payload?.status) {
            setLoading(false);
            navigate("/admin/category-list");
          }
        })
      : dispatch(updateCategoryData(formData)).then((val) => {
          if (val?.payload?.status) {
            setLoading(false);
            navigate("/admin/category-list");
          }
        });

    setLoading(false);
  };

  ////////////// This is for add Update image video ///////////////
  const imageHandle = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    let checkImage = file.type.match(/image/i);

    if (!checkImage) {
      setImageError("Please upload an image");
      return;
    } else {
      // Handle the image upload
      const newImageData = {
        type: "image",
        pre: URL.createObjectURL(file)
      };
      setImageData(newImageData);
      setImageDataNew(file);
    }
  };

  useEffect(() => {
    if (singleCategoryData) {
      const newImageData = {
        type: "image",
        pre: `${imageUrl}/${singleCategoryData.Category_Image}`,
        backend: true
      };
      setImageData(newImageData);
      setImageDataNew(newImageData);
    }
  }, [singleCategoryData]);

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        {singleCategoryData?._id ? "Update" : "Add"} Category
      </Index.Typography>
      <Index.Box className="page-border table-bg-main">
        <PageIndex.Formik
          initialValues={initialValues}
          onSubmit={handleCategorySubmit}
          validationSchema={categorySchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setErrors,
            setFieldError
          }) => (
            <PageIndex.Form>
              {console.log(values, "values22222", errors)}
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input add-game-row">
                        <Index.FormHelperText className="form-lable add-game-lables">
                          Category Images
                        </Index.FormHelperText>

                        <Index.Box className="grid-row">
                          <Index.Box className="grid-main">
                            <Index.Box className="display-row-add-user">
                              {imageData && imageData.type === "image" ? (
                                <Index.Box sx={{ gridColumn: "span 12" }}>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 3"
                                    }}
                                  >
                                    {console.log(
                                      imageData,
                                      "imageData",
                                      singleCategoryData?.Category_Image
                                    )}
                                    <Index.Box className="category-img-icon">
                                      {/* Displayed image */}
                                      <img
                                        className="upload-img-slider"
                                        src={imageData.pre}
                                        // src= {!values.Category_Image ? PageIndex.Png.flexo : imageData.pre }
                                        alt="Advertisement"
                                        // Add onClick handler to trigger file input click
                                        onClick={() =>
                                          document
                                            .getElementById("image-input")
                                            .click()
                                        }
                                      />
                                      {/* Hidden file input */}
                                      <input
                                        hidden
                                        id="image-input"
                                        accept="image/*"
                                        name="image"
                                        type="file"
                                        onChange={(e) => {
                                          imageHandle(e);
                                          setFieldValue(
                                            "Category_Image",
                                            e?.target?.files[0]
                                          );
                                        }}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              ) : (
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 3",
                                    md: "span 3",
                                    lg: "span 3"
                                  }}
                                >
                                  {/* Image upload button */}
                                  <Index.Box className="images-upload-btn">
                                    <Index.Button
                                      variant="contained"
                                      component="label"
                                      className="save-user-btn border-btn image-upload-btn"
                                    >
                                      <img
                                        alt="img"
                                        src={PageIndex.Svg.plusblack}
                                        className="upload-img"
                                      />
                                      <input
                                        hidden
                                        accept="image/*"
                                        name="image"
                                        type="file"
                                        onChange={(e) => {
                                          imageHandle(e);
                                          setFieldValue(
                                            "Category_Image",
                                            e?.target?.files[0]
                                          );
                                        }}
                                      />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        {/* <Index.Typography className="error-msg">
                          {imageError}
                        </Index.Typography> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Category Type<span>*</span>
                        </Index.FormHelperText>
                        <FormControl className="control-form-common">
                          <RadioGroup
                            className="radio-group-controls"
                            row
                            name="Category_Type"
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Top"
                            value={values?.Category_Type} // Set value prop to Formik value
                            onChange={(event) => {
                              setFieldValue(
                                "Category_Type",
                                event?.target?.value
                              );
                              console.log(
                                event?.target?.value,
                                "3232232323232",
                                singleCategoryData?.categoryType,
                                values
                              );
                              if (event?.target?.value) {
                                if (!singleCategoryData?._id) {
                                  setFieldValue("Category_Name", "");
                                  setFieldTouched("Category_Name", false);
                                  setFieldError("Category_Name", "");
                                  setFieldValue("Category_Number", "");
                                  setFieldTouched("Category_Number", false);
                                  setFieldError("Category_Number", "");
                                  setFieldValue("Category_Image", "");
                                  setFieldTouched("Category_Image", false);
                                  setFieldError("Category_Image", "");
                                  setImageData([]);
                                } else if (
                                  event?.target?.value !==
                                  singleCategoryData?.categoryType
                                ) {
                                  setFieldTouched("Category_Image", false);
                                  setFieldError("Category_Image", "");
                                  setImageData([]);
                                  setFieldValue("Category_Name", "");
                                  setFieldTouched("Category_Name", false);
                                  setFieldError("Category_Name", "");
                                  setFieldValue("Category_Number", "");
                                  setFieldTouched("Category_Number", false);
                                  setFieldError("Category_Number", "");
                                } else {
                                  const newImageData = {
                                    type: "image",
                                    pre: `${imageUrl}/${singleCategoryData.Category_Image}`,
                                    backend: true
                                  };
                                  setImageData(newImageData);
                                  setImageDataNew(newImageData);
                                  setFieldValue(
                                    "Category_Name",
                                    singleCategoryData?.Category_Name
                                  );
                                  setFieldTouched("Category_Name", false);
                                  setFieldError("Category_Name", "");
                                  setFieldValue(
                                    "Category_Number",
                                    singleCategoryData?.categoryNumber
                                  );
                                  setFieldTouched("Category_Number", false);
                                  setFieldError("Category_Number", "");
                                }
                              }
                            }}

                            // Update Formik value
                          >
                            <FormControlLabel
                              className="control-label-common"
                              value="Top"
                              control={<Radio />}
                              label="Top"
                            />
                            <FormControlLabel
                              className="control-label-common"
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                        </FormControl>
                        {errors?.Category_Type && touched?.Category_Type ? (
                          <Index.Typography className="error-msg">
                            {errors?.Category_Type}
                          </Index.Typography>
                        ) : null}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Category Name<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            name="Category_Name"
                            autoComplete="off"
                            placeholder="Please enter category"
                            as={Index.TextField}
                            disabled={values?.Category_Type === "Top"}
                            value={
                              values?.Category_Type === "Top"
                                ? values?.Category_Type
                                : values.Category_Name
                            }
                            // label="Carpet Area"
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.trimStart();
                              value = value.replace(/\s\s+/g, " ");
                              const regex = /^[A-Za-z][A-Za-z\s]*$/;
                              if (regex.test(value) || value === "") {
                                setFieldValue(
                                  "Category_Name",
                                  value.slice(0, 30)
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={Boolean(errors.Category_Name)}
                          />
                          {errors?.Category_Name && touched?.Category_Name ? (
                            <Index.Typography className="error-msg">
                              {errors?.Category_Name}
                            </Index.Typography>
                          ) : null}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    {values?.Category_Type == "Top" && (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6"
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Category Number<span>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <PageIndex.Field
                              name="Category_Number"
                              autoComplete="off"
                              placeholder="Please enter category number"
                              as={Index.TextField}
                              // label="Carpet Area"
                              variant="outlined"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              // type="number"
                              type="text" // Use text to allow pattern matching
                              pattern="\d*" // Only allow digits  // Prevent decimal inputs
                              onWheel={(e) => e.target.blur()}
                              onInput={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                if (
                                  /^\d*$/.test(newValue) &&
                                  parseInt(newValue, 10) <= 200
                                ) {
                                  // Ensure only digits and within limit
                                  setFieldValue("Category_Number", newValue);
                                } else {
                                  e.target.value = newValue.slice(0, -1); // Remove the last character if invalid
                                }
                              }}
                              onBlur={handleBlur}
                            />
                            {errors?.Category_Number &&
                            touched?.Category_Number ? (
                              <Index.Typography className="error-msg">
                                {errors?.Category_Number}
                              </Index.Typography>
                            ) : null}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="discard-btn-main border-btn-main">
                          <Index.Button
                            className="discard-user-btn border-btn"
                            onClick={() => navigate("/admin/category-list")}
                          >
                            Discard
                          </Index.Button>
                        </Index.Box>
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <ButtonMvpLoader
                                color="white"
                                loading={loading}
                              />
                            ) : (
                              <>
                                <img
                                  alt="imgcategory"
                                  src={PageIndex.Svg.save}
                                  className="user-save-icon"
                                ></img>
                                {singleCategoryData?._id ? "Update" : "Save"}
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
}
