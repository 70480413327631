import React, { useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import { imageUrl } from "../../../config/DataService";
import { logout } from "../../../redux/slices/AdminSlice";

export default function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(props.open);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = PageIndex?.useNavigate();
  const dispatch = PageIndex?.useDispatch();
  const open = Boolean(anchorEl);
  const { adminData } = PageIndex.useSelector((state) => state.admin);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutAdmin = () => {
    dispatch(logout());
    handleClose();
    navigate("/admin/login");
  };
  const handleOpenAccountSetting = () => {
    handleClose();
    navigate("/admin/account-setting");
  };
  const handleButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
    props?.setOpen(!props?.open);
    document.body.classList[props?.open ? "add" : "remove"]("body-overflow");
  };

  return (
    <Index.Box
      className={`admin-header-main ${
        props.open ? "active" : "admin-header-deactive"
      }`}
    >
      <Index.Box className="admin-header-left">
        <Index.Box className="admin-header-logo-main">
          <img
            src={PageIndex.Png.flexoLogo}
            className="admin-sidebar-logo"
            alt="logo"
          />
          <Index.Button
            className="admin-bergur-button"
            onClick={handleButtonClick}
          >
            <img
              src={isMenuOpen ? PageIndex.Svg.bergurmenu : PageIndex.Png.back} // Adjust the icon based on the state
              className="bergurmenu-icon"
              alt="dashboard logo"
            />
          </Index.Button>
        </Index.Box>
        <Index.Box className="admin-header-right">
          <Index.Box className="admin-header-drop-main">
            <Index.Button
              className="drop-header-btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Index.Box className="flex-drop-main">
                <img
                  src={
                    adminData?.image === "false"
                      ? Index.Png.usericon
                      : `${imageUrl}/${adminData?.image}`
                  }
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    {adminData?.name}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Button>
          </Index.Box>
          <Index.Menu
            className="drop-header-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <Index.MenuItem
              onClick={handleOpenAccountSetting}
              className="drop-header-menuitem"
            >
              <img
                src={PageIndex.Svg.settings}
                className="drop-header"
                alt="settings"
              />
              Account Settings
            </Index.MenuItem>
            <Index.MenuItem
              onClick={handleLogoutAdmin}
              className="drop-header-menuitem"
            >
              <img
                src={PageIndex.Svg.edit1}
                className="drop-header"
                alt="sign out"
              />{" "}
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
