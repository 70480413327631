import React, { useEffect, useRef, useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import {
  RoleForgotPassword,
  loginAdmin,
  roleLogin,
  verfiyRoleOtp
} from "../../../redux/slices/AdminService";
import { imageUrl } from "../../../config/DataService";
import ButtonMvpLoader from "../../common/buttonMvpLoader/ButtonMvpLoader";
import OtpInput from "react-otp-input";
import { logout } from "../../../redux/slices/AdminSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
};

export default function Sidebar(props) {
  const navigate = PageIndex.useNavigate();
  const { adminData } = PageIndex.useSelector((state) => state.admin);
  const { advertiserData } = PageIndex.useSelector((state) => state.admin);

  // State to manage the submenu open/close
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Function to toggle the submenu
  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleLogoutAdmin = () => {
    dispatch(logout());
    handleClose();
    navigate("/");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = PageIndex?.useDispatch();

  // Close SubMenu when Click on OutSide of the Sidebar
  const sidebarRef = useRef(null);

  const handleClickOutsideSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSubmenuOpen(false);
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, []);

  //End

  // export default function Sidebar() {
  // const props = true;
  const [screen, setScreen] = useState("");
  const [toogle, setToogle] = useState(true);

  const handleToogle = () => {
    if (toogle) {
      setToogle(false);
    }
    setToogle(true);
  };

  const location = PageIndex.useLocation();

  useEffect(() => {
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  const handleClickOutside = () => {
    if (screen == "Mobile") {
      props.setOpen(!props.open);
      document.body.classList[props.open ? "add" : "remove"]("body-overflow");
    }
  };

  return (
    <>
      {/* <Index.Box className={`overlay-section ${(screen == "Mobile" ? !props.open : props.open) ? "active" : ""}`} onClick={handleClickOutside}></Index.Box> */}
      <Index.Box
        className={`admin-sidebar-main ${
          (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
        }`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={PageIndex.Png.flexoLogo}
              className="admin-sidebar-logo res-admin-sidebar-logo dashboard-logo-flexo"
              alt="logo"
              onClick={() => {
                navigate("/advertiser/dashboard");
              }}
            />
            <img
              src={PageIndex.Png.flexo}
              className="admin-sidebar-logo res-admin-sidebar-logo mobile-logo-flexo"
              alt="logo"
              onClick={() => {
                navigate("/advertiser/dashboard");
              }}
            />

            <Index.Button
              className="admin-bergur-button custom-ui-btn"
              onClick={() => {
                props.setOpen(!props.open);
                setIsDropdownVisible(false);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
            >
              <img
                src={PageIndex.Svg.leftburgur}
                className="bergurmenu-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>

          </Index.Box>

          <Index.Box className="user-details-show">
            <Index.Box className="flex-userlisting" onClick={toggleDropdown}>
              <Index.Box className="flex-gap-between">
                <Index.Box className="flex-user-img">
                  <img
                    src={
                      advertiserData?.image
                        ? `${imageUrl}/${advertiserData?.image}`
                        : PageIndex.Png.userbg
                    }
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                </Index.Box>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    {advertiserData?.name ? advertiserData?.name : "Advertiser"}
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="admin-header-drop admin-header-role"
                  >
                    {advertiserData?.role
                      ? advertiserData?.role?.toString()?.toUpperCase()
                      : ""}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box
                className={`down-uparrow-btn ${
                  isDropdownVisible ? "open" : ""
                }`}
              >
                <Index.Button className="updown-btn">
                  <img
                    src={PageIndex.Svg.downup}
                    className="downup-icon"
                    alt="down"
                  />
                </Index.Button>
              </Index.Box>
            </Index.Box>
            <Index.Box
              className={`user-logout-menu-show ${
                isDropdownVisible ? "open" : ""
              }`}
            >
              <Index.List className="user-list-drop">
                <Index.Typography component="p" className="submenu-title">
                  {" "}
                  {advertiserData?.name ? advertiserData?.name : "Advertiser"}
                </Index.Typography>
                <Index.ListItem className="user-listitem-drop deacive-user-show"></Index.ListItem>
                <Index.ListItem
                  className="user-listitem-drop"
                  onClick={() => {
                    navigate("/advertiser/account-setting");
                  }}
                >
                  <Index.Link> Account Setting</Index.Link>
                </Index.ListItem>
                <Index.ListItem
                  className="user-listitem-drop"
                  onClick={handleLogoutAdmin}
                >
                  <Index.Link> Sign out</Index.Link>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem
                className={`admin-sidebar-listitem ${
                  location.pathname === "/advertiser/dashboard" ? "active" : ""
                }`}
              >
                <Index.Link
                  to="/advertiser/dashboard"
                  className={`admin-sidebar-link ${
                    location.pathname === "/advertiser/dashboard"
                      ? "active"
                      : ""
                  }`}
                  onClick={handleClickOutside}
                >
                  <Index.Box className="sidebar-icon-content">
                    <PageIndex.Svg.dashboard />
                  </Index.Box>
                  <Index.Typography
                    className="title-sidebar-content"
                    component="p"
                    variant="p"
                  >
                    Dashboard
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className={`admin-sidebar-listitem ${
                  location.pathname === "/advertiser/advertisment-list"
                    ? "active"
                    : ""
                }`}
              >
                <Index.Link
                  to="/advertiser/advertisment-list"
                  className={`admin-sidebar-link ${
                    location.pathname === "/advertiser/advertisment-list"
                      ? "active"
                      : ""
                  }`}
                  onClick={handleClickOutside}
                >
                  <Index.Box className="sidebar-icon-content">
                    <PageIndex.Svg.advertisement />
                  </Index.Box>
                  <Index.Typography
                    className="title-sidebar-content"
                    component="p"
                    variant="p"
                  >
                    Advertisement
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className={`admin-sidebar-listitem ${
                  location.pathname === "/advertiser/advertiser-transaction"
                    ? "active"
                    : ""
                }`}
              >
                <Index.Link
                  to="/advertiser/advertiser-transaction"
                  className={`admin-sidebar-link ${
                    location.pathname === "/advertiser/advertiser-transaction"
                      ? "active"
                      : ""
                  }`}
                  onClick={handleClickOutside}
                >
                  <Index.Box className="sidebar-icon-content">
                    <PageIndex.Svg.transaction />
                  </Index.Box>
                  <Index.Typography
                    className="title-sidebar-content"
                    component="p"
                    variant="p"
                  >
                    Transaction
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className={`admin-sidebar-listitem ${
                  location.pathname === "/advertiser/advertiser-deposit-history"
                    ? "active"
                    : ""
                }`}
              >
                <Index.Link
                  to="/advertiser/advertiser-deposit-history"
                  className={`admin-sidebar-link ${
                    location.pathname ===
                    "/advertiser/advertiser-deposit-history"
                      ? "active"
                      : ""
                  }`}
                  onClick={handleClickOutside}
                >
                  <Index.Box className="sidebar-icon-content">
                    <PageIndex.Svg.deposit />
                  </Index.Box>
                  <Index.Typography
                    className="title-sidebar-content"
                    component="p"
                    variant="p"
                  >
                    Deposit History
                  </Index.Typography>
                </Index.Link>
              </Index.ListItem>
              {/* <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/advertiser-list"
                  className={`admin-sidebar-link ${
                    location.pathname === "/admin/advertiser-list"
                      ? "active"
                      : ""
                  }`}
                >
                  <img
                    src={PageIndex.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Advertiser
                </Index.Link>
              </Index.ListItem> */}

              {/* <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                onClick={handleClickAccount}
              >
                <Index.Link className="admin-sidebar-link">
                  <img
                    src={PageIndex.Svg.changepassword}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />{" "}
                  CMS
                  {open ? (
                    <Index.ExpandMore className="expandmore-icon" />
                  ) : (
                    <Index.ExpandLess className="expandless-icon" />
                  )}
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link className="admin-sidebar-link  active">
                            About
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link className="admin-sidebar-link ">
                            Terms & Conditions
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link className="admin-sidebar-link ">
                            Privacy Policy
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem> */}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
