import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { windowScreen } from "../../../../components/common/windowScreen/windowScreen";

export default function LayOut() {
  const [open, setOpen] = useState(true);
  const [screen, setScreen] = useState("");
  const location = PageIndex.useLocation();
  const handleClickOutside = () => {
    if (screen == "Mobile") {
      setOpen(!open);
      document.body.classList[open ? "add" : "remove"]("body-overflow");
    }
  };
  const handleClickAccount = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  
  useEffect(() => {
    let detectScreen =windowScreen();
    console.log(detectScreen,"detectScreen");
  }, [window.screen.availHeight]);



  return (
    <div>
      <Index.Box
        className={`dashboard-main ${open ? "active" : "dashboard-main-overlay"
          }`}
      >
        <Index.Box
          className={`overlay-section ${(screen == "Mobile" ? !open : open) ? "active" : ""
            }`}
          onClick={handleClickOutside}
        ></Index.Box>

        <Index.Box
          className={`dashboard-left-main mobile-menu ${open ? "active" : "admin-sidebar-deactive"
            }`}
        >
          <PageIndex.Sidebar open={open} setOpen={setOpen} />
        </Index.Box>

        <Index.Box
          className={`dashboard-top-header mobile-logo ${open ? "active" : "admin-sidebar-deactive"
            }`}
        >
          <PageIndex.Header open={open} setOpen={setOpen} />
        </Index.Box>


        <Index.Box
          className={`dashboard-right-main ${open ? "active" : "admin-sidebar-left"
            }`}
        >
          {/* <PageIndex.Header setOpen={setOpen} open={open}/> */}
          <Index.Box
            className="dashboard-containt-main"
          >
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
