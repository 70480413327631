import React, { useEffect, useRef, useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import {
  RoleForgotPassword,
  loginAdmin,
  roleLogin,
  verfiyRoleOtp,
} from "../../../redux/slices/AdminService";
import { imageUrl } from "../../../config/DataService";
import ButtonMvpLoader from "../../common/buttonMvpLoader/ButtonMvpLoader";
import OtpInput from "react-otp-input";
import { logout } from "../../../redux/slices/AdminSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function Sidebar(props) {
  const navigate = PageIndex.useNavigate();
  const { adminData } = PageIndex.useSelector((state) => state.admin);

  // State to manage the submenu open/close
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Function to toggle the submenu
  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleLogoutAdmin = () => {
    dispatch(logout());
    handleClose();
    navigate("/admin/login");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = PageIndex?.useDispatch();

  // Close SubMenu when Click on OutSide of the Sidebar
  const sidebarRef = useRef(null);

  const handleClickOutsideSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSubmenuOpen(false);
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, []);

  const [screen, setScreen] = useState("");
  const [toogle, setToogle] = useState(true);

  const handleToogle = () => {
    if (toogle) {
      setToogle(false);
    }
    setToogle(true);
  };

  const location = PageIndex.useLocation();

  useEffect(() => {
    if (window.screen.width < 991) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  const handleClickOutside = () => {
    if (screen == "Mobile") {
      props.setOpen(!props.open);
      document.body.classList[props.open ? "add" : "remove"]("body-overflow");
    }
  };

  console.log(adminData, ":adminData");

  // Determine if the current location is part of the CMS section
  const isCmsSection =
    location.pathname === "/admin/terms-condition" ||
    location.pathname === "/admin/privacy-policy";

  return (
    <Index.Box
      ref={sidebarRef}
      className={`admin-sidebar-main ${
        (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
      }`}
    >
      <Index.Box className="admin-sidebar-inner-main">
        <Index.Box className="admin-sidebar-logo-main">
          <img
            src={PageIndex.Png.flexoLogo}
            className="admin-sidebar-logo res-admin-sidebar-logo dashboard-logo-flexo"
            alt="logo"
            onClick={() => {
              navigate("/admin/dashboard");
            }}
          />
          <img
            src={PageIndex.Png.flexo}
            className="admin-sidebar-logo res-admin-sidebar-logo mobile-logo-flexo"
            alt="logo"
            onClick={() => {
              navigate("/admin/dashboard");
            }}
          />

          <Index.Button
            className="admin-bergur-button custom-ui-btn"
            onClick={() => {
              props.setOpen(!props.open);
              setIsDropdownVisible(false);
              document.body.classList[props.open ? "add" : "remove"](
                "body-overflow"
              );
            }}
          >
            <img
              src={PageIndex.Svg.leftburgur}
              className="bergurmenu-icon"
              alt="dashboard logo"
            ></img>
          </Index.Button>
        </Index.Box>

        <Index.Box className="user-details-show">
          <Index.Box className="flex-userlisting" onClick={toggleDropdown}>
            <Index.Box className="flex-gap-between">
              <Index.Box className="flex-user-img">
                <img
                  src={
                    adminData?.image === "false"
                      ? Index.Png.usericon
                      : `${imageUrl}/${adminData?.image}`
                  }
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
              </Index.Box>
              <Index.Box className="title-admin-drop">
                <Index.Typography
                  variant="h5"
                  component="h5"
                  className="admin-header-drop"
                >
                  {adminData?.name}
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="admin-header-drop admin-header-role"
                >
                  {adminData?.role
                    ? adminData?.role?.toString()?.toUpperCase()
                    : ""}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              className={`down-uparrow-btn ${isDropdownVisible ? "open" : ""}`}
            >
              <Index.Button className="updown-btn">
                <img
                  src={PageIndex.Svg.downup}
                  className="downup-icon"
                  alt="down"
                />
              </Index.Button>
            </Index.Box>
          </Index.Box>
          <Index.Box
            className={`user-logout-menu-show ${
              isDropdownVisible ? "open" : ""
            }`}
          >
            <Index.List className="user-list-drop">
              <Index.Typography component="p" className="submenu-title">
                {" "}
                {adminData?.name}
              </Index.Typography>
              <Index.ListItem className="user-listitem-drop deacive-user-show"></Index.ListItem>
              <Index.ListItem
                className="user-listitem-drop"
                onClick={() => {
                  navigate("/admin/account-setting");
                }}
              >
                <Index.Link> Account Setting</Index.Link>
              </Index.ListItem>
              <Index.ListItem
                className="user-listitem-drop"
                onClick={handleLogoutAdmin}
              >
                <Index.Link> Sign out</Index.Link>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-sidebar-list-main">
          <Index.List className="admin-sidebar-list">
            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/dashboard" ? "active" : ""
              }`}
            >
              <Index.Link
                to="/admin/dashboard"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/dashboard" ? "active" : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.dashboard />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Dashboard
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/user-management-list"
                  ? "active"
                  : ""
              }`}
            >
              <Index.Link
                to="/admin/user-management-list"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/user-management-list"
                    ? "active"
                    : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.userlist />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  User Management
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/advertiser-list" ? "active" : ""
              }`}
            >
              <Index.Link
                to="/admin/advertiser-list"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/advertiser-list" ? "active" : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.advertiser />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Advertiser
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>
            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/category-list" ? "active" : ""
              }`}
            >
              <Index.Link
                to="/admin/category-list"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/category-list" ? "active" : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.categories />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Category
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>
            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/advertisement-list"
                  ? "active"
                  : ""
              }`}
            >
              <Index.Link
                to="/admin/advertisement-list"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/advertisement-list"
                    ? "active"
                    : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.advertisement />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Advertisement
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/advertiser-transaction" ? "active" : ""
              }`}
            >
              <Index.Link
                to="/admin/advertiser-transaction"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/advertiser-transaction" ? "active" : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.transaction />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Advertiser Transaction
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>
            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/transaction" ? "active" : ""
              }`}
            >
              <Index.Link
                to="/admin/transaction"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/transaction" ? "active" : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.transaction />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Transaction
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>
            <Index.ListItem
              className={`admin-sidebar-listitem ${
                location.pathname === "/admin/deposit-history" ? "active" : ""
              }`}
            >
              <Index.Link
                to="/admin/deposit-history"
                className={`admin-sidebar-link ${
                  location.pathname === "/admin/deposit-history" ? "active" : ""
                }`}
                onClick={handleClickOutside}
              >
                <Index.Box className="sidebar-icon-content">
                  <PageIndex.Svg.deposit />
                </Index.Box>
                <Index.Typography
                  className="title-sidebar-content"
                  component="p"
                  variant="p"
                >
                  Deposit History
                </Index.Typography>
              </Index.Link>
            </Index.ListItem>
            <Index.Box className="submenu-sidebr-details submenu-inner">
              <Index.ListItem
                className={`admin-sidebar-listitem ${
                  isCmsSection ? "active" : ""
                }`}
                onClick={() => {
                  setSubmenuOpen(!submenuOpen);
                  // setSubmenuOpen(false);
                  document.body.classList[submenuOpen ? "add" : "remove"](
                    "body-overflow"
                  );
                }}
              >
                <Index.Link className="admin-sidebar-link">
                  <Index.Box className="sidebar-icon-content">
                    <PageIndex.Svg.userlist />
                  </Index.Box>
                  <Index.Typography
                    className="title-sidebar-content"
                    component="p"
                    variant="p"
                  >
                    CMS
                  </Index.Typography>

                  <Index.Box
              className={`down-uparrow-btn ${submenuOpen ? "open" : ""}`}
            >
              <Index.Button className="updown-btn">
                <img
                  src={PageIndex.Svg.downup}
                  className="downup-icon"
                  alt="down"
                />
              </Index.Button>
            </Index.Box>
                </Index.Link>
              </Index.ListItem>

              {submenuOpen && (
                <Index.List className="sidebar-inner-drop-ul ">
                  <Index.ListItem
                    className={`sidebar-inner-dropli admin-listitem-menu ${
                      location.pathname === "/admin/terms-condition"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Index.Link
                      className="admin-menu-link"
                      to="/admin/terms-condition"
                    >
                      <Index.Box className="w-100-admin-hreader">
                        <Index.Typography
                          className="admin-icon-title"
                          component="p"
                          variant="p"
                        >
                          Terms & Condition
                        </Index.Typography>
                      </Index.Box>
                    </Index.Link>
                  </Index.ListItem>
                  <Index.ListItem
                    className={`sidebar-inner-dropli admin-listitem-menu ${
                      location.pathname === "/admin/privacy-policy"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Index.Link
                      className="admin-menu-link"
                      to="/admin/privacy-policy"
                    >
                      <Index.Box className="w-100-admin-hreader">
                        <Index.Typography
                          className="admin-icon-title"
                          component="p"
                          variant="p"
                        >
                          Privacy Policy
                        </Index.Typography>
                      </Index.Box>
                    </Index.Link>
                  </Index.ListItem>
                </Index.List>
              )}
            </Index.Box>
          </Index.List>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
