import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { imageUrl } from "../../../../config/DataService";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TextareaAutosize } from "@mui/material";

export default function ViewAdvertisement() {
  const [videoData, setVideoData] = useState(null);
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const advertisementSingleData = params?.state;

  useEffect(() => {
    if (advertisementSingleData) {
      const newVideoData = {
        type: advertisementSingleData.video.split(".")[1],
        pre: `${imageUrl}/${advertisementSingleData.video}`,
        backend: true
      };
      setVideoData(newVideoData);
    }
  }, [advertisementSingleData]);

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        View Advertisement 
      </Index.Typography>
      <Index.Box className="table-bg-main">
        <Index.Box className="add-user-data-main">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            >
               {(advertisementSingleData?.addType === "video" ||
                advertisementSingleData?.addType === "image") && (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input add-game-row">
                    <Index.FormHelperText className="form-lable add-game-lables">
                      Advertisement{" "}
                      {advertisementSingleData?.video === "video"
                        ? "Video"
                        : "Image"}
                    </Index.FormHelperText>
                    <Index.Box className="grid-row">
                      <Index.Box className="grid-main">
                        <Index.Box className="display-row-add-user position-relative">
                          {videoData ? (
                            <Index.Box>
                              <Index.Box className="document-close-set"></Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 3",
                                  md: "span 3",
                                  lg: "span 3"
                                }}
                              >
                                <Index.Box className="max-video-view-adver">
                                  {videoData.type === "mp4" ? (
                                    <video
                                      className="upload-img-slider"
                                      controls
                                    >
                                      <source
                                        src={videoData?.pre}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    <img
                                      src={videoData?.pre}
                                      className="upload-img-slider"
                                      alt="img"
                                    />
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          ) : (
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 3",
                                md: "span 3",
                                lg: "span 6"
                              }}
                            >
                              <Index.Box className="images-upload-btn">
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="save-user-btn border-btn image-upload-btn"
                                >
                                  <img
                                    alt="img"
                                    src={Index.Svg.plus}
                                    className="upload-img"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Title 
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      disabled
                      value={advertisementSingleData?.title}
                      variant="outlined"
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Category
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        variant="outlined"
                        fullWidth
                        disabled
                        multiple // Enable multiple selections
                        value={advertisementSingleData?.category?.map(
                          (category) => category?.Category_Name
                        )}
                        id="fullWidth"
                        className="form-control"
                      ></Index.TextField>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Max Spend Limit Per Transaction
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      value={
                        advertisementSingleData?.maxSpendLimitPerTransaction
                      }
                      variant="outlined"
                      fullWidth
                      disabled
                      id="fullWidth"
                      className="form-control"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Start Date
                  </Index.FormHelperText>
                  <Index.Box className="form-group custom-datepicker">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="date-adapter-main"
                    >
                      <DemoContainer
                        components={["DatePicker", "DatePicker"]}
                        className="date-adapter-container"
                      >
                        <MobileDatePicker
                          className="date-adapter-picker"
                          disabled
                          value={dayjs(advertisementSingleData?.startDate)}
                          format="DD/MM/YYYY"
                          disablePast
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    End Date
                  </Index.FormHelperText>
                  <Index.Box className="form-group custom-datepicker">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="date-adapter-main"
                    >
                      <DemoContainer
                        components={["DatePicker", "DatePicker"]}
                        className="date-adapter-container"
                      >
                        <MobileDatePicker
                          className="date-adapter-picker"
                          disabled
                          value={dayjs(advertisementSingleData?.endDate)}
                          format="DD/MM/YYYY"
                          minDate={
                            dayjs(advertisementSingleData?.startDate) ||
                            undefined
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Select Advertisement Type
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        value={advertisementSingleData?.addType}
                        variant="outlined"
                        fullWidth
                        displayEmpty
                        disabled
                        id="fullWidth"
                        className="form-control"
                      ></Index.TextField>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              {(advertisementSingleData?.addType == "image" ||
                advertisementSingleData?.addType == "text") && (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Duration
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        value={advertisementSingleData?.duration}
                        disabled
                        variant="outlined"
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6"
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                   Redirect Link
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      value={advertisementSingleData?.hyperLink}
                      variant="outlined"
                      fullWidth
                      disabled
                      id="fullWidth"
                      className="form-control"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {(advertisementSingleData?.addType == "video" ||
                advertisementSingleData?.addType == "image") && (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Description
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <TextareaAutosize
                        value={advertisementSingleData?.description}
                        variant="outlined"
                        disabled
                        fullWidth
                        id="fullWidth"
                        className="form-control textarea-filed"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}

              {advertisementSingleData?.addType === "text" && (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Text Details
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <TextareaAutosize
                        value={advertisementSingleData?.textDetails}
                        variant="outlined"
                        disabled
                        fullWidth
                        id="fullWidth"
                        className="form-control textarea-filed"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}

             

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12"
                }}
                className="grid-column"
              >
                <Index.Box className="user-btn-flex">
                  <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button
                      className="discard-user-btn border-btn"
                      onClick={() =>  navigate("/admin/advertisement-list")}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
