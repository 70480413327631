import React from "react";
import "./mvploader.css";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import Gif from "../../../assets/Gif";

const MvpLoader = () => {
  return (

    <>

      <Index.Box className="spinner-box-custom">
        <Index.Box className=" custom-loader-mvp">
          <img
            // src={PageIndex.Png.flexo}
            src={Gif.Loader_mvp}
            width="512"
            height="512"
            className="head-logo-icon"
            alt="Company Logo"
          />

        </Index.Box>
      </Index.Box>
    </>
  );
};

export default MvpLoader;
