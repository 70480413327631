import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ dashboardData }) => {
  console.log(dashboardData, "dashboardData32323");
  const [chartDetails, setChartDetails] = useState([]);
  const [totalAdvertisementsCount, setTotalAdvertisementsCount] = useState([]);

  useEffect(() => {
    for (const [key, value] of Object.entries(
      dashboardData?.activeTotalShownCountByDate
    )) {
      console.log(key, value, ":nnnnnnn"); // first one, second two
      let obj = {
        x: key,
        y: value
      };

      setChartDetails((prev) => [...prev, obj]);
    }
    for (const [key, value] of Object.entries(
      dashboardData?.totalShownCountByDate
    )) {
      let obj = {
        x: key,
        y: value
      };

      setTotalAdvertisementsCount((prev) => [...prev, obj]);
    }
  }, []);

  const dates = [
    { x: "2024-02-06", y: 1500000 },
    { x: "2024-03-12", y: 2000000 },
    { x: "2024-04-08", y: 1000000 },
    { x: "2024-05-02", y: 3000000 },
    { x: "2024-08-16", y: 2500000 }
    // Add more data points as needed
  ];
  console.log(chartDetails, ":chartDetails", dates);
  // const dates = dashboardData[0]?.
  // const [series, setSeries] = useState([
  //   {
  //     name: "Advertisement",
  //     data: chartDetails // Make sure `dates` is defined or passed as a prop
  //   }
  // ]);

  const series = [
    {
      name: "Advertisement",
      data: chartDetails // Make sure `dates` is defined or passed as a prop
    },{
      name: "Total Advertisements",
      data: totalAdvertisementsCount
    }
  ];

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: "zoom"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    title: {
      // text: "Stock Price Movement",
      align: "left"
    },
    stroke: {
      colors: ["#f9cc5d"]
    },
    fill: {
      colors: ["#f9cc5d", "#fe806b", "#4cb58c"],
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val;
        }
      },
      title: {
        text: "Active",
        color: '#ffffff',
      }
    },
    xaxis: {
      type: "datetime"
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
