import dashboard from "../assets/images/svg/dashboard";
import notification from "./images/svg/notification.svg";
import settings from "./images/svg/settings.svg";
import userlist from "./images/svg/userlist";
import editpage from "./images/svg/editpage";
import changepassword from "./images/svg/change-password.svg";
import profile from "./images/svg/profile.svg";
import profilegrey from "./images/svg/profile-grey.svg";
import dashicon1 from "./images/svg/dash1.svg";
import dashicon2 from "./images/svg/dash2.svg";
import dashicon3 from "./images/svg/dash3.svg";
import dashicon4 from "./images/svg/dash4.svg";
import save from "./images/svg/save.svg";
import edit from "./images/svg/edit.svg";
import blueedit from "./images/svg/blue-edit.svg";
import trash from "./images/svg/trash.svg";
import share from "./images/svg/share.svg";
import yelloweye from "./images/svg/yellow-eye.svg";
import plus from "./images/svg/plus.svg";
import down from "./images/svg/down.svg";
import search from "./images/svg/search.svg";
import closecircle from "./images/svg/close-circle.svg";
import bergurmenu from "./images/svg/bergur-menu.svg";
import paginationleft from "./images/svg/pagination-left.svg";
import paginationright from "./images/svg/pagination-left.svg";
import filter from "./images/svg/filter.svg";
import close from "./images/svg/close.svg";
import instagram from "./images/svg/instagram.svg";
import youtube from "./images/svg/youtube.svg";
import skype from "./images/svg/skype.svg";
import linkdin from "./images/svg/linkedin.svg";
import twitter from "./images/svg/twitter.svg";
import closeblack from "./images/svg/close-black.svg";
import deleteicon from "./images/svg/delete.svg";
import facebook from "./images/svg/facebook.svg";
import discord from "./images/svg/discord.svg";
import usercard from "./images/svg/usercard";
import copy from "./images/svg/copy.svg";
import web from "./images/svg/web.svg";
import downArrow from "./images/svg/downArrow.svg";
import dots from "./images/svg/dots.svg";
import add from "./images/svg/add.svg";
import swap from "./images/svg/swap.svg";
import account from "./images/svg/account.svg";
import bell from "./images/svg/bell.svg";
import settingmodal from "./images/svg/settingmodal.svg";
import profile2 from "./images/svg/profile2.svg";
import leftArrow from "./images/svg/leftArrow.svg";
import settingSwap from "./images/svg/settingSwap.svg";
import swapDown from "./images/svg/swapDown.svg";
import closeSetting from "./images/svg/closeSetting.svg";
import search2 from "./images/svg/search2.svg";
import rightArrow from "./images/svg/rightArrow.svg";
import contact from "./images/svg/contact.svg";
import alert from "./images/svg/alert.svg";
import network from "./images/svg/network.svg";
import arrowBack from "./images/svg/arrowBack.svg";
import padlock from "./images/svg/padlock.svg";
import contactGray from "./images/svg/contactGray.svg";
import scanner from "./images/svg/scanner.svg";
import information from "./images/svg/information.svg";
import userimg from './images/svg/user-img.svg'
import edittext from './images/svg/edit-text.svg'
import qrcodeimg from './images/svg/qr-code.svg'
import closeQr from './images/svg/closeQr.svg'
import group from './images/svg/group.svg'
import cross from './images/svg/cross.svg'
import edit1 from './images/svg/edit1.svg'
import frame from './images/svg/frame.svg'
import appleIcon from './images/svg/appleIcon.svg'
import playStore from './images/svg/playStore.svg'
import frameTwo from './images/svg/flexo.svg'
import downloads from './images/svg/downloads.svg'
import leftburgur from './images/svg/leftburgur.svg'
import downup from './images/svg/downup.svg'
import DownArrow from "./images/svg/DownArrow";
import UpArrow from "./images/svg/UpArrow";
import Cms from "./images/svg/Cms";
import Revenue from "./images/svg/Revenue.svg";
import TotalImpression from "./images/svg/TotalImpression.svg";
import AdvertiseDistribution from "./images/svg/AdvertiseDistribution.svg";
import SubscribeUser from "./images/svg/SubscribeUser.svg";
import Distributer from "./images/svg/Distributer.svg";
import Publisher from "./images/svg/Publisher.svg";
import Campaigns from "./images/svg/Campaigns.svg";
import Income from "./images/svg/Income.svg";
import Category from "./images/svg/Category.svg";
import TotalAdvertisement from "./images/svg/TotalAdvertisement.svg";
import TotalPublisher from "./images/svg/TotalPublisher.svg";
import ActiveCampaigns from "./images/svg/ActiveCampaigns.svg";
import plusblack from "./images/svg/plus-black.svg";
import transaction from "./images/svg/transaction";
import deposit from "./images/svg/deposit";
import categories from "./images/svg/categories";
import advertisement from "./images/svg/advertisement";
import advertiser from "./images/svg/advertiser";

const Svg = {
  dashboard,
  notification,
  settings,
  changepassword,
  editpage,
  userlist,
  profile,
  dashicon1,
  dashicon2,
  dashicon3,
  dashicon4,
  save,
  edit,
  blueedit,
  trash,
  yelloweye,
  plus,
  down,
  search,
  closecircle,
  profilegrey,
  bergurmenu,
  paginationleft,
  paginationright,
  filter,
  close,
  instagram,
  youtube,
  skype,
  twitter,
  linkdin,
  closeblack,
  deleteicon,
  facebook,
  discord,
  usercard,
  copy,
  web,
  downArrow,
  dots,
  add,
  swap,
  account,
  bell,
  profile2,
  settingmodal,
  leftArrow,
  settingSwap,
  swapDown,
  closeSetting,
  search2,
  rightArrow,
  contact,
  alert,
  network,
  arrowBack,
  padlock,
  contactGray,
  scanner,
  information,
  userimg,
  edittext,
  qrcodeimg,
  closeQr,
  group,
  cross,
  edit1,
  frame,
  playStore,
  appleIcon,
  frameTwo,
  downloads,
  leftburgur,
  downup,
  DownArrow,
  UpArrow,
  Cms,
  Revenue,
  TotalImpression,
  AdvertiseDistribution,
  SubscribeUser,
  Distributer,
  Publisher,
  Campaigns,
  Income,
  Category,
  TotalAdvertisement,
  TotalPublisher,
  ActiveCampaigns,
  plusblack,
  share,
  transaction,
  deposit,
  categories,
  advertisement,
  advertiser
};

export default Svg;
