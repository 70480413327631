import React, { useEffect, useState, useRef } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import "./advertisementlist.css";
import {
  createAdvertisementData,
  listOfAllCategory
} from "../../../../redux/slices/AdminService";
import { imageUrl } from "../../../../config/DataService";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { advertisementSchema } from "../../../../validations/Validation";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TextareaAutosize, fabClasses } from "@mui/material";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";

export default function AddUpdateAdvertisement() {
  // for open handleChangedropdown
  const [videoData, setVideoData] = useState(null);
  const [videoDataNew, setVideoDataNew] = useState(null);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const advertisementSingleData = params?.state?.selectedRow;
  const editCopyType = params?.state?.type;
  const formRef = useRef();

  const categoryListData = () => {
    dispatch(listOfAllCategory()).then((res) => {
      setAllCategoryList(res?.payload?.data);
    });
  };
  useEffect(() => {
    categoryListData();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (advertisementSingleData) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [advertisementSingleData]);
  const initialValues = {
    title: advertisementSingleData?._id ? advertisementSingleData?.title : "",
    category: advertisementSingleData?._id
      ? advertisementSingleData?.category?.map((category) => category?._id)
      : [],
    duration: advertisementSingleData?._id
      ? advertisementSingleData?.duration
      : "",
    maxSpendLimitPerTransaction: advertisementSingleData?._id
      ? advertisementSingleData?.maxSpendLimitPerTransaction
      : "",
    startDate: advertisementSingleData?._id
      ? advertisementSingleData?.startDate
      : "",
    endDate: advertisementSingleData?._id
      ? advertisementSingleData?.endDate
      : "",
    video: advertisementSingleData?._id ? advertisementSingleData?.video : "",

    advertisement_type: advertisementSingleData?._id
      ? advertisementSingleData?.addType
      : "video",
    description: advertisementSingleData?._id
      ? advertisementSingleData?.description
      : "",
    hyperLink: advertisementSingleData?._id
      ? advertisementSingleData?.hyperLink
      : "",
    textdetails: advertisementSingleData?._id
      ? advertisementSingleData?.textDetails
      : ""
  };

  const handleAdvertisementSubmit = (values) => {
    let duration = values.duration == null ? videoDuration : values.duration;
    setLoading(true);
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("category", JSON.stringify(values?.category));
    formData.append("duration", duration);
    formData.append(
      "maxSpendLimitPerTransaction",
      values.maxSpendLimitPerTransaction
    );
    formData.append("startDate", values?.startDate?.format('YYYY-MM-DD'));
    formData.append("endDate", values.endDate?.format('YYYY-MM-DD'));
    formData.append("hyperLink", values.hyperLink);
    formData.append("description", values.description);
    formData.append("addType", values.advertisement_type);
    formData.append("textDetails", values.textdetails);
    formData.append("file", values?.video || values?.image);

    if (advertisementSingleData?._id && editCopyType == "edit") {
      formData.append("id", advertisementSingleData?._id);
    }
    dispatch(createAdvertisementData(formData)).then((val) => {
      if (val?.payload?.status) {
        setLoading(false);
        navigate("/advertiser/advertisment-list");
      }
    });
  };
  ////////////// This is for add Update image video ///////////////

  const videoHandle = (e, advertisementType, setFieldValue, setFieldError) => {
    if (advertisementType == "video") {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      const allowedTypes = ["video/mp4"];
      if (allowedTypes.includes(file.type)) {
        let imageOrVideo;
        imageOrVideo = {
          type: file.type.split("/")[1],
          pre: URL.createObjectURL(file)
        };
        setVideoData(imageOrVideo);
        setVideoDataNew(file);
        setFieldValue("video", file);
      } else {
        setFieldError("video", "Please upload a valid  video (MP4) file");
      }
    } else if (advertisementType == "image") {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedTypes.includes(file.type)) {
        let imageOrVideo;
        imageOrVideo = {
          type: file.type.split("/")[1],
          pre: URL.createObjectURL(file)
        };
        setVideoData(imageOrVideo);
        setVideoDataNew(file);
        setFieldValue("video", file);
      } else {
        setFieldError(
          "video",
          "Please upload a valid image (JPEG, JPG)  file"
        );
      }
    }
  };

  useEffect(() => {
    if (advertisementSingleData) {
      const newVideoData = {
        type: advertisementSingleData.video.split(".")[1],
        pre: `${imageUrl}/${advertisementSingleData.video}`,
        backend: true
      };
      setVideoData(newVideoData);
      setVideoDataNew(`${advertisementSingleData.video}`);
    }
  }, [advertisementSingleData]);

  const stopWhiteSpace = (val) => {
    let checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    }
  };

  const handleInput = (value, fieldName) => {
    let { setFieldValue } = formRef.current;
    stopWhiteSpace(value)
      ? setFieldValue(fieldName, value)
      : setFieldValue(fieldName, "");
  };

  return (
    <>
      {loading ? (
        <MvpLoader color="#1B698E" loading={loading} />
      ) : (
        <Index.Box className="dashboard-content add-user-containt">
          <Index.Typography
            className="admin-page-title main-heading"
            component="h2"
            variant="h2"
          >
            {advertisementSingleData?._id && editCopyType == "edit"
              ? "Update"
              : editCopyType == "copy"
              ? "Copy"
              : "Add"}{" "}
            Advertisement
          </Index.Typography>

          <Index.Box className="table-bg-main">
            <PageIndex.Formik
              initialValues={initialValues}
              onSubmit={handleAdvertisementSubmit}
              validationSchema={advertisementSchema}
              innerRef={(f) => (formRef.current = f)}
              enableReinitialize
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
                setFieldError,
                setFieldTouched
              }) => (
                <PageIndex.Form onSubmit={handleSubmit}>
                  <Index.Box className="add-user-data-main">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                      >

{(values.advertisement_type === "video" ||
                          values.advertisement_type === "image") && (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12"
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input add-game-row">
                              <Index.FormHelperText className="form-lable add-game-lables">
                                Advertisement{" "}
                                {values.advertisement_type === "video"
                                  ? "Video"
                                  : "Image"}
                                <span>*</span>
                              </Index.FormHelperText>
                              <Index.Box className="grid-row">
                                <Index.Box className="grid-main">
                                  <Index.Box className="display-row-add-user position-relative">
                                    {videoData ? (
                                      <Index.Box>
                                        <Index.Button
                                          sx={{ cursor: "pointer" }}
                                          className="document-close"
                                          onClick={() => {
                                            setVideoData(null);
                                            setFieldValue("video", null);
                                          }}
                                        >
                                          <Index.CloseTwoToneIcon />
                                        </Index.Button>
                                        <Index.Box className="document-close-set"></Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 3",
                                            md: "span 3",
                                            lg: "span 3"
                                          }}
                                        >
                                        <Index.Box className="max-video-view-adver">
                                            {videoData.type === "mp4" ? (
                                              <video
                                                className="upload-img-slider"
                                                controls
                                              >
                                                <source
                                                  src={videoData?.pre}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            ) : (
                                              <img
                                                src={videoData?.pre}
                                                className="upload-img-slider"
                                                alt="img"
                                              />
                                            )}
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    ) : (
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 3",
                                          md: "span 3",
                                          lg: "span 6"
                                        }}
                                      >
                                        <Index.Box className="images-upload-btn">
                                          <Index.Button
                                            variant="contained"
                                            component="label"
                                            className="save-user-btn border-btn image-upload-btn"
                                          >
                                              <img
                                        alt="img"
                                        src={PageIndex.Svg.plusblack}
                                        className="upload-img"
                                      />

                                            <input
                                              hidden
                                              key={values.advertisement_type}
                                              type="file"
                                              onChange={(event) => {
                                                setFieldTouched("video", true);

                                                const file =
                                                  event.target.files[0];
                                                const video =
                                                  document.createElement(
                                                    "video"
                                                  );
                                                const videoURL =
                                                  URL.createObjectURL(file);

                                                video.preload = "metadata";
                                                video.src = videoURL;

                                                video.onloadedmetadata = () => {
                                                  if (
                                                    video.duration < 15 ||
                                                    video.duration > 20
                                                  ) {
                                                    if (
                                                      values?.advertisement_type ==
                                                      "video"
                                                    ) {
                                                      setFieldError(
                                                        "video",
                                                        "Video duration should be between 15 or 20 seconds"
                                                      );
                                                    } else {
                                                      setFieldError(
                                                        "video",
                                                        "Please upload a valid image (JPEG, JPG)  file"
                                                      );
                                                    }
                                                  } else {
                                                    setVideoDuration(
                                                      video.duration
                                                    );
                                                    setFieldValue(
                                                      "duration",
                                                      video.duration
                                                    );
                                                    videoHandle(
                                                      event,
                                                      values.advertisement_type,
                                                      setFieldValue,
                                                      setFieldError
                                                    );
                                                  }
                                                };
                                                video.onerror = () => {
                                                  videoHandle(
                                                    event,
                                                    values.advertisement_type,
                                                    setFieldValue,
                                                    setFieldError
                                                  );
                                                };
                                              }}
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.Box>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              {errors?.video && touched?.video ? (
                                <Index.Typography className="error-msg">
                                  {errors?.video}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        )}
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6"
                          }}
                          className="grid-column"
                        >
                          {console.log(errors,values,"errors408")}
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Title<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <PageIndex.Field
                                name="title"
                                value={stopWhiteSpace(values?.title)}
                                as={Index.TextField}
                                placeholder="Please enter title"
                                variant="outlined"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                onChange={(event) => {
                                  let inputValue = event.target.value;
                                  inputValue = inputValue.replace(
                                    /\s\s+/g,
                                    " "
                                  );

                                  if (inputValue?.length <= 25) {
                                    handleInput(inputValue, "title");
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(errors.title)}
                              />
                              {errors?.title && touched?.title ? (
                                <Index.Typography className="error-msg">
                                  {errors?.title}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Category<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="dropdown-box">
                              <Index.Box className="form-control drop-form-control">
                                <Index.Select
                                  name="category"
                                  variant="outlined"
                                  fullWidth
                                  multiple // Enable multiple selections
                                  value={values?.category}
                                  id="fullWidth"
                                  className="dropdown-select drop-select dropdown-property"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                    error={Boolean(errors.category)}
                                  displayEmpty // Allow display of empty value
                                  renderValue={(selected) =>
                                    selected.length
                                      ? selected
                                          .map(
                                            (value) =>
                                              allCategoryList?.find(
                                                (category) =>
                                                  category._id === value
                                              )?.Category_Name
                                          )
                                          .join(", ")
                                      : "Select Category"
                                  }
                                >
                                  <Index.MenuItem
                                    value=""
                                    disabled
                                    className="drop-menuitem"
                                  >
                                    Select Category
                                  </Index.MenuItem>
                                  {allCategoryList?.map((category) => (
                                    <Index.MenuItem
                                      value={category._id}
                                      key={category._id}
                                    >
                                      {category.Category_Name}
                                    </Index.MenuItem>
                                  ))}
                                </Index.Select>
                                {errors?.category && touched?.category ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.category}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Max Spend Limit Per Transaction<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <PageIndex.Field
                                type="text"
                                name="maxSpendLimitPerTransaction"
                                value={values?.maxSpendLimitPerTransaction}
                                as={Index.TextField}
                                placeholder="Please enter max spend limit per transaction"
                                // label="Carpet Area"
                                variant="outlined"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  setFieldValue(
                                    "maxSpendLimitPerTransaction",
                                    inputValue.slice(0, 10)
                                  );
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors.maxSpendLimitPerTransaction
                                )}
                              />
                              {errors?.maxSpendLimitPerTransaction &&
                              touched?.maxSpendLimitPerTransaction ? (
                                <Index.Typography className="error-msg">
                                  {errors?.maxSpendLimitPerTransaction}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                  md: "span 4",
                  lg: "span 4"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Start Date<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group custom-datepicker">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                className="date-adapter-main"
                              >
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                  className="date-adapter-container"
                                >
                                  <MobileDatePicker
                                    className="date-adapter-picker"
                                    name="startDate"
                                    value={dayjs(values?.startDate)}
                                    onChange={(e) => {
                                      setFieldValue("startDate", e);
                                    }}
                                    format="DD/MM/YYYY"
                                    disablePast
                                  />
                                </DemoContainer>
                              </LocalizationProvider>

                              {errors?.startDate && touched?.startDate ? (
                                <Index.Typography className="error-msg">
                                  {errors?.startDate}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              End Date<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group custom-datepicker">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                className="date-adapter-main"
                              >
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                  className="date-adapter-container"
                                >
                                  <MobileDatePicker
                                    className="date-adapter-picker"
                                    name="endDate"
                                    value={dayjs(values?.endDate)}
                                    onChange={(e) => {
                                      setFieldValue("endDate", e);
                                    }}
                                    format="DD/MM/YYYY"
                                    minDate={
                                      dayjs(values?.startDate) || undefined
                                    }
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              {errors?.endDate && touched?.endDate ? (
                                <Index.Typography className="error-msg">
                                  {errors?.endDate}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Select Advertisement Type<span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="dropdown-box">
                              <Index.Box className="form-control drop-form-control">
                                <PageIndex.Field
                                  name="advertisement_type"
                                  value={values?.advertisement_type}
                                  as={Index.Select}
                                  variant="outlined"
                                  fullWidth
                                  displayEmpty
                                  id="fullWidth"
                                  className="dropdown-select drop-select dropdown-property"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "advertisement_type",
                                      e.target.value
                                    );
                                    setVideoData(null);
                                    setFieldValue("video", null);
                                    setFieldTouched("video", false);
                                    setFieldValue("duration", "");
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.title)}
                                >
                                  <Index.MenuItem
                                    value=""
                                    disabled
                                    className="drop-menuitem"
                                  >
                                    Select Advertisement Type
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value="text"
                                    className="drop-menuitem"
                                  >
                                    Text
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value="video"
                                    className="drop-menuitem"
                                  >
                                    Video
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value="image"
                                    className="drop-menuitem"
                                  >
                                    Image
                                  </Index.MenuItem>
                                </PageIndex.Field>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        {(values.advertisement_type == "image" ||
                          values.advertisement_type == "text") && (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6"
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Duration<span>*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  type="text"
                                  name="duration"
                                  value={values?.duration}
                                  as={Index.TextField}
                                  placeholder="Please enter duration"
                                  // label="Carpet Area"
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setFieldValue(
                                      "duration",
                                      inputValue.slice(0, 2)
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(errors.duration)}
                                />
                                {errors?.duration && touched?.duration ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.duration}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        )}

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Redirect Link <span>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <PageIndex.Field
                                name="hyperLink"
                                value={stopWhiteSpace(values?.hyperLink)}
                                as={Index.TextField}
                                placeholder="Please enter redirect link"
                                variant="outlined"
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^\S*$/; // Regex pattern to disallow whitespace

                                  if (regex.test(value) || value === "") {
                                    handleInput(value, "hyperLink");
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(errors.title)}
                              />
                              {errors?.hyperLink && touched?.hyperLink ? (
                                <Index.Typography className="error-msg">
                                  {errors?.hyperLink}
                                </Index.Typography>
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {(values.advertisement_type == "video" ||
                          values.advertisement_type == "image") && (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12"
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Description <span>*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="description"
                                  value={stopWhiteSpace(values?.description)}
                                  as={TextareaAutosize}
                                  placeholder="Please enter description"
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control textarea-filed"
                                  onChange={(event) => {
                                    let inputValue = event.target.value;
                                    inputValue = inputValue.replace(
                                      /\s\s+/g,
                                      " "
                                    );

                                    // if (inputValue?.length <= 1000) {
                                    //   handleInput(inputValue, "description");
                                    // }
                                    if (inputValue.length > 965) {
                                      inputValue = inputValue.slice(0, 965); // Slice input to 1000 characters
                                    }

                                    handleInput(inputValue, "description");
                                  }}
                                  onBlur={handleBlur}
                                />
                                {errors?.description && touched?.description ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.description}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        )}

                        {values.advertisement_type === "text" && (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12"
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Text Details <span>*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <PageIndex.Field
                                  name="textdetails"
                                  value={stopWhiteSpace(values?.textdetails)}
                                  as={TextareaAutosize}
                                  placeholder="Please enter textdetails"
                                  variant="outlined"
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control textarea-filed"
                                  onChange={(event) => {
                                    let inputValue = event.target.value;
                                    inputValue = inputValue.replace(
                                      /\s\s+/g,
                                      " "
                                    );

                                    if (inputValue?.length <= 500) {
                                      handleInput(inputValue, "textdetails");
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                {errors?.textdetails && touched?.textdetails ? (
                                  <Index.Typography className="error-msg">
                                    {errors?.textdetails}
                                  </Index.Typography>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        )}

                       

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12"
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-btn-flex">
                            <Index.Box className="discard-btn-main border-btn-main">
                              <Index.Button
                                className="discard-user-btn border-btn"
                                onClick={() =>
                                  navigate("/advertiser/advertisment-list")
                                }
                              >
                                Discard
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="save-btn-main border-btn-main">
                              <Index.Button
                                className="save-user-btn border-btn"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <ButtonMvpLoader
                                    color="white"
                                    loading={loading}
                                  />
                                ) : (
                                  <>
                                    <img
                                      alt="imgcategory"
                                      src={PageIndex.Svg.save}
                                      className="user-save-icon"
                                    ></img>
                                    {advertisementSingleData?._id &&
                                    editCopyType == "edit"
                                      ? "Update"
                                      : editCopyType == "copy"
                                      ? "Copy"
                                      : "Save"}
                                  </>
                                )}
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </PageIndex.Form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
