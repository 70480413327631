import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import { loginSchema } from "../../../../validations/Validation";
import { loginAdmin, roleLogin } from "../../../../redux/slices/AdminService";
import { useNavigate } from "react-router-dom";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";
import { useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
};

export default function Login({ handleForgot }) {
  //////////////////////////////////////////////////////////////////
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleOpenLogin = () => setOpenLogin(true);

  // for password eye hide and show
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = PageIndex?.useLocation();
  const navigate = useNavigate();
  const dispatch = PageIndex?.useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const SubmitForm = (values) => {
    setLoading(true);
const data = {
    email: values?.email?.toLowerCase(),
    password: values?.password
}

    location.pathname === "/admin/login"
      ? dispatch(loginAdmin(data)).then((res) => {
          setTimeout(() => {
            setLoading(false);
          }, 6000);
          if (res?.payload?.status == 200 || res?.payload?.status == 201) {
            setLoading(false);
            if (res?.payload?.data?.role === "Admin") {
              navigate("/admin/dashboard", {});
            }
          }
        })
      : dispatch(roleLogin(data)).then((res) => {
          setTimeout(() => {
            setLoading(false);
          }, 6000);
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            setLoading(false);
            if (res?.payload?.data?.role === "advertiser") {
              navigate(`/${res?.payload?.data?.role}/dashboard`);
            }
          }
        });
  };
  const { token } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!token) {
      handleOpenLogin();
    }
  }, []);

  let initialValues = {
    email: "",
    password: ""
  };
  return (
  
      <Index.Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal-login"
      >
        <Index.Box sx={style} className="delete-modal-inner-main">
          <Index.Box>
            <Index.Box className="modal-logo-sec cus-center">
              <img
                src={PageIndex.Png.flexoLogo}
                className="login-modal-logo"
                alt="logo"
              />
            </Index.Box>
            <Index.Formik
              validationSchema={loginSchema}
              initialValues={initialValues}
              onSubmit={SubmitForm}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                touched,
                dirty
              }) => (
                <Index.Form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <Index.Box className="admin-login-inner">
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Login your account.
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Email<span>*</span>
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Please enter email"
                          autoComplete="off"
                          name="email"
                          type="text"
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={handleChange}
                          error={Boolean(errors.email)}
                          helperText={touched.email}
                          maxLength={50}
                          onKeyDown={(e) =>
                            e.keyCode === 32 && e.preventDefault()
                          }
                        />
                        {errors?.email ? (
                          <Index.Typography className="error-msg">
                            {errors?.email}
                          </Index.Typography>
                        ) : null}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box mb-15">
                      <Index.FormHelperText className="form-lable">
                        Password<span>*</span>
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.OutlinedInput
                          className="form-control-eye from-control-password"
                          autoComplete="off"
                          name="password"
                          placeholder="Please enter password"
                          id="outlined-adornment-password"
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            if (inputValue.length <= 25) {
                              // Only update the value if it's not more than 30 characters
                              handleChange(event);
                            }
                          }}
                          error={Boolean(errors.password)}
                          type={showPassword ? "text" : "password"}
                          onKeyDown={(e) =>
                            e.keyCode === 32 && e.preventDefault()
                          }
                          endAdornment={
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                                onMouseUp={(event) => {
                                  event.preventDefault();
                                }}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Index.Visibility />
                                ) : (
                                  <Index.VisibilityOff />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          }
                        />
                        {errors?.password && touched?.password ? (
                          <Index.Typography className="error-msg">
                            {errors?.password}
                          </Index.Typography>
                        ) : null}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="flex-all forgot-row">
                      <Index.Box className="checkbox-main"></Index.Box>
                      <Index.Link
                        className="text-decoration-none admin-forgot-para common-para"
                        onClick={() => handleForgot()}
                      >
                        Forgot Password?
                      </Index.Link>
                    </Index.Box>
                    <Index.Box className="btn-main-primary login-btn-main">
                      <Index.Button
                        className="btn-primary login-btn"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <ButtonMvpLoader color="white" loading={loading} />
                        ) : (
                          "Login"
                        )}
                      </Index.Button>
                    </Index.Box>
                    {location.pathname === "/" ? (
                      <Index.Box className="admin-signup">
                        <Index.Typography>
                          Don't have an account ?{" "}
                          <Index.Link
                            className="text-decoration-none admin-forgot-para common-para"
                            to={"/signup"}
                          >
                            Sign Up
                          </Index.Link>
                        </Index.Typography>
                      </Index.Box>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Form>
              )}
            </Index.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

  );
}
