import React from "react";

function transaction() {
  return (
    <>
      <svg
        id="Layer_2"
        width="30"
        height="20"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 2"
      >
        <path d="m16 10c-.55 0-1 .45-1 1v.18c-1.16.41-2 1.51-2 2.82 0 1.65 1.35 3 3 3 .55 0 1 .45 1 1s-.45 1-1 1c-.42 0-.81-.28-.94-.68-.17-.52-.74-.81-1.26-.64s-.81.74-.64 1.26c.3.9.99 1.58 1.84 1.88v.18c0 .55.45 1 1 1s1-.45 1-1v-.18c1.16-.41 2-1.51 2-2.82 0-1.65-1.35-3-3-3-.55 0-1-.45-1-1s.45-1 1-1c.42 0 .81.28.94.68.17.52.74.81 1.26.64s.81-.74.64-1.26c-.3-.9-.99-1.58-1.84-1.88v-.18c0-.55-.45-1-1-1z" fill="#fff" />
        <path d="m24.49 6.15-.14-.12c-.13-.11-.25-.21-.35-.28-.51-.4-1.07-.77-1.7-1.12-.54-.29-1.04-.53-1.52-.72-5.13-2.04-11-.6-14.62 3.6l-.12.14c-.11.13-.22.26-.28.35-.08.1-.15.21-.23.32l-.42-1.45c-.15-.53-.71-.84-1.24-.69s-.84.71-.69 1.24l1.1 3.84c.07.25.24.47.48.6.15.08.32.12.48.12.09 0 .19-.01.28-.04l3.85-1.1c.53-.15.84-.71.69-1.24s-.7-.84-1.24-.69l-1.6.46c.05-.06.09-.13.14-.19.06-.09.14-.17.21-.25l.12-.15c3.05-3.53 8.02-4.75 12.35-3.02.41.16.83.36 1.29.61.53.3 1 .6 1.47.97.09.06.17.13.25.2l.15.12c2.41 2.09 3.79 5.12 3.79 8.31 0 .55.45 1 1 1s1-.45 1-1c0-3.78-1.63-7.36-4.51-9.85z" fill="#fff"/>
        <path d="m7.51 25.85.14.12c.13.11.26.22.35.28.51.41 1.07.77 1.7 1.12.54.29 1.04.53 1.52.72 1.55.62 3.17.91 4.78.91 3.7 0 7.32-1.59 9.85-4.51l.12-.14c.11-.13.21-.25.28-.35.08-.1.15-.21.23-.32l.41 1.45c.12.44.53.72.96.72.09 0 .18-.01.28-.04.53-.15.84-.71.69-1.24l-1.1-3.85c-.07-.25-.24-.47-.48-.6-.23-.13-.51-.16-.76-.09l-3.85 1.1c-.53.15-.84.71-.69 1.24s.71.84 1.24.69l1.6-.46c-.05.06-.09.13-.14.19-.06.09-.13.17-.2.25l-.13.15c-3.05 3.53-8.02 4.75-12.35 3.02-.4-.16-.83-.36-1.29-.62-.53-.29-1-.6-1.47-.97-.09-.06-.17-.13-.25-.21l-.15-.12c-2.41-2.08-3.79-5.12-3.79-8.31 0-.55-.45-1-1-1s-1 .45-1 1c0 3.78 1.64 7.36 4.51 9.85z" fill="#fff" />
      </svg>
    </>
  );
}

export default transaction;
