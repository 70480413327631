
import React, { useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import { imageUrl } from "../../../config/DataService";

export default function Header(props) {
  // for open in header profile menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = PageIndex?.useNavigate();
  const { advertiserData } = PageIndex.useSelector((state) => state.admin);

  // for open in header profile menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // for close in header profile menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  // for logout in header profile menu
  const handleLogoutAdmin = () => {
    handleClose();
    navigate("/");
  };

  // for open account setting
  const handleOpenAccountSetting = () => {
    handleClose();
    navigate("/advertiser/account-setting");
  };



  return (
    <Index.Box
      className={`admin-header-main ${
        props?.open ? "active" : "admin-header-deactive"
      }`}
    >
      <Index.Box className="admin-header-left">
        <Index.Box className="admin-header-logo-main">
          <img
            src={PageIndex.Png.flexoLogo}
            className="admin-sidebar-logo"
            alt="logo"
          />
          <Index.Button
            className="admin-bergur-button"
            onClick={() => {
              props?.setOpen(!props?.open);
              document.body.classList[props?.open ? "add" : "remove"](
                "body-overflow"
              );
            }}
          >
            <img
              src={PageIndex.Svg.bergurmenu}
              className="bergurmenu-icon"
              alt="dashboard logo"
            ></img>
          </Index.Button>
        </Index.Box>
        <Index.Box className="admin-header-right">
          <Index.Box className="admin-header-drop-main">
            <Index.Button
              className="drop-header-btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Index.Box className="flex-drop-main">
                <img
                  src={
                    advertiserData?.image
                      ? `${imageUrl}/${advertiserData?.image}`
                      : PageIndex.Png.usericon
                  }
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    {advertiserData?.name ? advertiserData?.name : "Advertiser"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Button>
          </Index.Box>
          <Index.Menu
            className="drop-header-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <Index.MenuItem
              onClick={handleOpenAccountSetting}
              className="drop-header-menuitem"
            >
              <img
                src={PageIndex?.Svg?.settings}
                className="drop-header"
                alt="Account Settings"
              />{" "}
              Account Settings
            </Index.MenuItem>
            <Index.MenuItem
              onClick={handleLogoutAdmin}
              className="drop-header-menuitem"
            >
              <img
                src={PageIndex?.Svg?.edit}
                className="drop-header"
                alt="Sign Out"
              />{" "}
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
