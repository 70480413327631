import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../../../components/advertiser/advertiserdefaultLayout/Sidebar";
import Header from "../../../../components/advertiser/advertiserdefaultLayout/Header";
import Index from "../../../Index";
import { windowScreen } from "../../../../components/common/windowScreen/windowScreen";


export default function AdvertiserLayOut() {
  const [open, setOpen] = useState(true);
  const [screen, setScreen] = useState("");
  const handleClickOutside = () => {
    if (screen == "Mobile") {
      setOpen(!open);
      document.body.classList[open ? "add" : "remove"]("body-overflow");
    }
  };

  useEffect(() => {
    let detectScreen =windowScreen();
    console.log(detectScreen,"detectScreen");
  }, [window.screen.availHeight]);

  return (
    <div>
      <Index.Box
        className={`dashboard-main ${
          open ? "active" : "dashboard-main-overlay"
        }`}
      >
        <Index.Box
          className={`overlay-section ${
            (screen == "Mobile" ? !open : open) ? "active" : ""
          }`}
          onClick={handleClickOutside}
        ></Index.Box>

        <Index.Box
          className={`dashboard-left-main mobile-menu ${
            open ? "active" : "admin-sidebar-deactive"
          }`}
        >
          <Sidebar open={open} setOpen={setOpen} />
        </Index.Box>

        <Index.Box
          className={`dashboard-top-header mobile-logo ${
            open ? "active" : "admin-sidebar-deactive"
          }`}
        >
          <Header open={open} setOpen={setOpen} />
        </Index.Box>

        <Index.Box
          className={`dashboard-right-main ${
            open ? "active" : "admin-sidebar-left"
          }`}
        >
          <Index.Box className="dashboard-containt-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
