import React from 'react'
import Index from '../../../container/Index';
import { Skeleton } from "@mui/material";


const SkeletonTable = ({ colSpan }) => {
    console.log(colSpan , ":colSpan")
    return (
      <>
        {[...Array(5)].map((item) => {
          return (
            <>
              <Index.TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                {[...Array(colSpan)]?.map((_, index) => {
                  return (
                    <>
                      <Index.TableCell component="td" variant="td" sx={{borderColor: "#3f395e"}}>
                        <Skeleton variant="text" />
                      </Index.TableCell>
                    </>
                  );
                })}
              </Index.TableRow>
            </>
          );
        })}
      </>
    );
  };
  

export default SkeletonTable


