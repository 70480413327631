import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { useDispatch } from "react-redux";
import { addEditCMS, getCMSData } from "../../../../redux/slices/AdminService";
import { termsConditionSchema } from "../../../../validations/Validation";
import ButtonMvpLoader from "../../../../components/common/buttonMvpLoader/ButtonMvpLoader";

const TermsCondition = () => {
  const navigate = PageIndex.useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const getCMS = () => {
    dispatch(getCMSData()).then((res) => {
      setData(res?.payload?.data?.termsCondition);
    });
  };

  console.log(data,"33333333333333333")

  useEffect(() => {
    getCMS();
  }, []);
  const initialValues = {
    cmsType: 1,
    description: data ? data : ""
  };
  const handleCmsSubmit = (values) => {
    setLoading(true);
    dispatch(addEditCMS(values)).then((res) => {

        getCMS();
        setLoading(false);
        navigate("/admin/dashboard")
      
    });
  };

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title main-heading"
        component="h2"
        variant="h2"
      >
        Terms & Conditions
      </Index.Typography>
      <Index.Box className="page-border table-bg-main">
        <PageIndex.Formik
          initialValues={initialValues}
          onSubmit={handleCmsSubmit}
          validationSchema={termsConditionSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setErrors,
            setFieldError
          }) => (
            <PageIndex.Form onSubmit={handleSubmit}>
              {console.log(values, "values22222")}
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Title<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <PageIndex.Field
                            name="Category_Name"
                            autoComplete="off"
                            value="Terms & Condition"
                            // label="Carpet Area"
                            as={Index.TextField}
                            variant="outlined"
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Description<span>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <CKEditor
                            editor={ClassicEditor}
                            name="description"
                            contenteditable="true"
                            onReady={(editor) => {}}
                            data={values?.description || data}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("description", data);
                            }}
                          />
                        </Index.Box>
                        {errors?.description && touched?.description ? (
                          <Index.Typography className="error-msg">
                            {errors?.description}
                          </Index.Typography>
                        ) : null}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12"
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="discard-btn-main border-btn-main">
                          <Index.Button
                            className="discard-user-btn border-btn"
                            onClick={() => navigate("/admin/dashboard")}
                          >
                            Discard
                          </Index.Button>
                        </Index.Box>
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <ButtonMvpLoader
                                color="white"
                                loading={loading}
                              />
                            ) : (
                            <>
                              <img
                                alt="imgcategory"
                                src={PageIndex.Svg.save}
                                className="user-save-icon"
                              ></img>
                              {"Submit"}
                            </>
                             )} 
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
};

export default TermsCondition;
