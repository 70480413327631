import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  allWalletAdress,
  listOfDashboardData,
  transationHistory,
} from "../../../../redux/slices/AdminService";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import ApexChart from "./ApexChart";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SkeletonTable from "../../../../components/common/skeletonTable/SkeletonTable";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#171423",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

export default function Home() {
  const [allDashboardData, setAllDashboardData] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = PageIndex?.useDispatch();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [search, setSearch] = useState("");
  const [transationHistoryData, setTrnsationHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [walletAdress,setWalletAdress]=useState({})
  console.log(allDashboardData, "allDashboardData");
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(listOfDashboardData()).then((res) => {
      if (res?.payload?.status === 200) {
        setAllDashboardData(res?.payload?.data);

        setLoading(false);
      }
    });
  }, []);
  useEffect(() => {
    dispatch(transationHistory()).then((res) => {
      let data = res?.payload?.data?.slice(-10); // Get the first 10 items
      data=data.reverse();
      setTrnsationHistoryData(data);
      if (data) {
        setLoading(false);
      }
    });
  }, []);
  useEffect(() => {
    let filteredItems = transationHistoryData;
    if (search) {
      filteredItems = transationHistoryData?.filter((item) => {
        return !search
          ? item
          : item?.TransactionId?.from
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.TransactionId?.to
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.TransactionId?.transactionHash
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.TransactionId?.value
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.TransactionId?.url
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.TransactionId?.gasless
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.TransactionId?.gasFees
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase());
      });
    }

    setFilteredData(filteredItems);
  }, [transationHistoryData, search]);

  useEffect(()=>{
    dispatch(allWalletAdress()).then((res)=>{
      setWalletAdress(res?.payload?.data)
    })
  },[])

  const stopWhiteSpace = (val) => {
    if (!val) {
      return "";
    }
    const checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    } else {
      return undefined;
    }
  };

  return (
    <>
      {loading ? (
        <MvpLoader color="#1B698E" loading={loading} />
      ) : (
        <Index.Box className="dashboard-content home-page-content">
          <Index.Grid container spacing={2}>
            <Index.Grid item xs={12} md={8} lg={8}>
              <Index.Typography
                className="admin-page-title main-heading"
                component="h2"
                variant="h2"
              >
                Dashboard
              </Index.Typography>
            </Index.Grid>
            <Index.Grid item xs={12} md={4} lg={4} textAlign="end">
              <Index.Typography
                className="admin-page-title sub-text-cont"
                component="h6"
                variant="h6"
              >
                Wallet:{" "}
                <span className="sub-digit">
                  {allDashboardData?.balance?.toFixed(10)}
                </span>
              </Index.Typography>
            </Index.Grid>
          </Index.Grid>

          <Index.Grid container columnSpacing={1}>
            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
              {/* New Dash Board Code */}
              <Index.Box className="dashboard-back-control-custom card-main-colleft">
                {/* <Index.Box className="copy-text-bg-main"> </Index.Box >  */}
                <Index.Box className="graph-box-cont graph-advertisement">
                  <Index.Box className="flex-advertisemant-main graph-box-card-pd">
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={4} lg={2.4}>
                        <Index.Box className="dashboard-back-control" onClick={() => {navigate("/admin/advertiser-list")}}>
                          <Index.Box className="copy-text-bg-main"> </Index.Box>
                          <Index.Box className="dashboard-box-cont">
                            <Index.Box className="admin-dashboard-box">
                              <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                <Index.Box className="admin-dash-right">
                                  <img
                                    src={PageIndex.Png.totaladvertisers}
                                    // src={PageIndex.Png.flexo}
                                    className="dash-icons"
                                    alt="dashboard icon"
                                  />

                                  <Index.Tooltip
                                    title={
                                      allDashboardData?.counts?.advertisersCount
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Index.Typography
                                      className="admin-dash-price"
                                      variant="h1"
                                      component="h1"
                                    >
                                      {loading
                                        ? "0"
                                        : allDashboardData?.counts
                                          ?.advertisersCount}
                                    </Index.Typography>
                                  </Index.Tooltip>
                                </Index.Box>
                                <Index.Box className="admin-dash-left">
                                  <Index.Typography
                                    className="admin-dash-text"
                                    variant="p"
                                    component="p"
                                  >
                                    Total Advertisers
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4} lg={2.4}>
                        <Index.Box className="dashboard-back-control" onClick={() => {navigate("/admin/advertisement-list")}}>
                          <Index.Box className="copy-text-bg-main"> </Index.Box>
                          <Index.Box className="dashboard-box-cont">
                            <Index.Box className="admin-dashboard-box">
                              <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                <Index.Box className="admin-dash-right">
                                  <img
                                    src={PageIndex.Png.totaladvertisement}
                                    // src={PageIndex.Png.flexo}
                                    className="dash-icons"
                                    alt="dashboard icon"
                                  />
                                  <Index.Tooltip
                                    title={
                                      allDashboardData?.counts
                                        ?.advertisementsCount
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Index.Typography
                                      className="admin-dash-price"
                                      variant="h1"
                                      component="h1"
                                    >
                                      {loading
                                        ? "0"
                                        : allDashboardData?.counts
                                          ?.advertisementsCount}
                                    </Index.Typography>
                                  </Index.Tooltip>
                                </Index.Box>
                                <Index.Box className="admin-dash-left">
                                  <Index.Typography
                                    className="admin-dash-text"
                                    variant="p"
                                    component="p"
                                  >
                                    Total Advertisement
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4} lg={2.4}>
                        <Index.Box className="dashboard-back-control" onClick={() => {navigate("/admin/category-list")}}>
                          <Index.Box className="copy-text-bg-main"> </Index.Box>
                          <Index.Box className="dashboard-box-cont">
                            <Index.Box className="admin-dashboard-box">
                              <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                <Index.Box className="admin-dash-right">
                                  <img
                                    src={PageIndex.Png.Totalcategories}
                                    // src={PageIndex.Png.flexo}
                                    className="dash-icons"
                                    alt="dashboard icon"
                                  />
                                  <Index.Tooltip
                                    title={
                                      allDashboardData?.counts?.categoriesCount
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Index.Typography
                                      className="admin-dash-price"
                                      variant="h1"
                                      component="h1"
                                    >
                                      {loading
                                        ? "0"
                                        : allDashboardData?.counts
                                          ?.categoriesCount}
                                    </Index.Typography>
                                  </Index.Tooltip>
                                </Index.Box>
                                <Index.Box className="admin-dash-left">
                                  <Index.Typography
                                    className="admin-dash-text"
                                    variant="p"
                                    component="p"
                                  >
                                    Total Category
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6} lg={2.4}>
                        <Index.Box className="dashboard-back-control"onClick={() => {navigate("/admin/advertisement-list")}}>
                          <Index.Box className="copy-text-bg-main"> </Index.Box>
                          <Index.Box className="dashboard-box-cont">
                            <Index.Box className="admin-dashboard-box">
                              <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                <Index.Box className="admin-dash-right">
                                  <img
                                    src={PageIndex.Png.activeadvertisement}
                                    // src={PageIndex.Png.flexo}
                                    className="dash-icons"
                                    alt="dashboard icon"
                                  />

                                  <Index.Tooltip
                                    title={
                                      allDashboardData?.counts
                                        ?.activeAdvertisementsCount
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Index.Typography
                                      className="admin-dash-price"
                                      variant="h1"
                                      component="h1"
                                    >
                                      {loading
                                        ? "0"
                                        : allDashboardData?.counts
                                          ?.activeAdvertisementsCount}
                                    </Index.Typography>
                                  </Index.Tooltip>
                                </Index.Box>
                                <Index.Box className="admin-dash-left">
                                  <Index.Typography
                                    className="admin-dash-text"
                                    variant="p"
                                    component="p"
                                  >
                                    Active Advertisement
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6} lg={2.4}>
                        <Index.Box className="dashboard-back-control" onClick={() => {navigate("/admin/user-management-list")}} >
                          <Index.Box className="copy-text-bg-main"> </Index.Box>
                          <Index.Box className="dashboard-box-cont">
                            <Index.Box className="admin-dashboard-box">
                              <Index.Box className="admin-dashboard-inner-box admin-dash-new-box">
                                <Index.Box className="admin-dash-right">
                                  <img
                                    src={PageIndex.Svg.TotalImpression}
                                    // src={PageIndex.Png.flexo}
                                    className="dash-icons"
                                    alt="dashboard icon"
                                  />
                                  <Index.Tooltip
                                    title={allDashboardData?.counts?.userCount}
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Index.Typography
                                      className="admin-dash-price"
                                      variant="h1"
                                      component="h1"
                                    >
                                      {loading
                                        ? "0"
                                        : allDashboardData?.counts?.userCount}
                                    </Index.Typography>
                                  </Index.Tooltip>
                                </Index.Box>
                                <Index.Box className="admin-dash-left">
                                  <Index.Typography
                                    className="admin-dash-text"
                                    variant="p"
                                    component="p"
                                  >
                                    Total Flexo User
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
              <Index.Box className="dashboard-back-control-custom card-main-colleft">
                {/* <Index.Box className="copy-text-bg-main"> </Index.Box >  */}
                <Index.Box className="graph-box-cont graph-advertisement">
                  <Index.Box className="flex-advertisemant-main graph-box-card-pd pt-card-line">
                    <Index.Grid container spacing={3} className="grid-line-ul">
                      <Index.Grid item xs={12} md={6} lg={6} className="grid-line-li">
                        <Index.Box className="box-show-dash-prices">
                          <Index.Box className="show-prices-pd">
                            <Index.Box className="flex-name-prices">
                              <Index.Box className="admin-dash-right">
                                <img
                                  src={PageIndex.Png.sponsoredgasfees}
                                  // src={PageIndex.Png.flexo}
                                  className="dash-icons"
                                  alt="dashboard icon"
                                />
                              </Index.Box>
                              <Index.Typography
                                className="admin-dash-text"
                                variant="p"
                                component="p"
                              >
                                Sponsored Gas Fees
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="show-prices-list">
                              <Index.Tooltip
                                title={
                                  allDashboardData?.counts?.sponsoredGasFees
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Index.Typography
                                  className="admin-dash-price"
                                  variant="h1"
                                  component="h1"
                                >
                                  {loading
                                    ? "0"
                                    : allDashboardData?.counts?.sponsoredGasFees?.toFixed(
                                      10
                                    )}
                                </Index.Typography>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} md={6} lg={6} className="grid-line-li">
                        <Index.Box className="box-show-dash-prices">
                          <Index.Box className="show-prices-pd">
                            <Index.Box className="flex-name-prices">
                              <Index.Box className="admin-dash-right">
                                <img
                                  src={PageIndex.Png.buybackwallet}
                                  // src={PageIndex.Png.flexo}
                                  className="dash-icons"
                                  alt="dashboard icon"
                                />
                              </Index.Box>
                              <Index.Typography
                                className="admin-dash-text"
                                variant="p"
                                component="p"
                              >
                                Buy Back Wallet{" "}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="show-prices-list">
                              <Index.Tooltip
                                  title={walletAdress?.buyBackBalance}
                                arrow
                                placement="bottom-start"
                              >
                                <Index.Typography
                                  className="admin-dash-price"
                                  variant="h1"
                                  component="h1"
                                >
                                  {loading
                                    ? "0"
                                      : walletAdress?.buyBackBalance == 0 
                                        ? "0.0000000000" : walletAdress?.buyBackBalance}
                                </Index.Typography>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} md={6} lg={6} className="grid-line-li">
                        <Index.Box className="box-show-dash-prices">
                          <Index.Box className="show-prices-pd">
                            <Index.Box className="flex-name-prices">
                              <Index.Box className="admin-dash-right">
                                <img
                                  src={PageIndex.Svg.TotalImpression}
                                  // src={PageIndex.Png.flexo}
                                  className="dash-icons"
                                  alt="dashboard icon"
                                />
                              </Index.Box>
                              <Index.Typography
                                className="admin-dash-text"
                                variant="p"
                                component="p"
                              >
                                Company Wallet
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="show-prices-list">
                              <Index.Tooltip
                                  title={walletAdress?.companyBalance}
                                arrow
                                placement="bottom-start"
                              >
                                <Index.Typography
                                  className="admin-dash-price"
                                  variant="h1"
                                  component="h1"
                                >
                                  {loading
                                    ? "0"
                                    :
                                      walletAdress?.companyBalance == 0 ?
                                        "0.0000000000" : walletAdress?.companyBalance}
                                </Index.Typography>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} md={6} lg={6} className="grid-line-li">
                        <Index.Box className="box-show-dash-prices">
                          <Index.Box className="show-prices-pd">
                            <Index.Box className="flex-name-prices">
                              <Index.Box className="admin-dash-right">
                                <img
                                  src={PageIndex.Png.gasfeewallet}
                                  // src={PageIndex.Png.flexo}
                                  className="dash-icons"
                                  alt="dashboard icon"
                                />
                              </Index.Box>
                              <Index.Typography
                                className="admin-dash-text"
                                variant="p"
                                component="p"
                              >
                                Gas Fees Wallet
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="show-prices-list">
                              <Index.Tooltip
                                  title={walletAdress?.gasFeesBalance}
                                arrow
                                placement="bottom-start"
                              >
                                <Index.Typography
                                  className="admin-dash-price"
                                  variant="h1"
                                  component="h1"
                                >
                                  {loading
                                    ? "0"
                                      : walletAdress?.gasFeesBalance == 0 ?
                                        "0.0000000000" : walletAdress?.gasFeesBalance}
                                </Index.Typography>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
              {allDashboardData?.counts && (
                <Index.Box className="graph-box-cont graph-advertisement">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} md={12} lg={2}>
                      <Index.Typography
                        className="graph-title"
                        component="h6"
                        variant="h6"
                      >
                        Advertisements
                      </Index.Typography>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="graph-box">
                        <ApexChart dashboardData={allDashboardData?.counts} />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              )}

              <Index.Box className="graph-box-cont">
                <Index.Grid container spacing={2}>
                  <Index.Grid item xs={12} md={12} lg={12}>
                    <Index.Box className="graph-box">
                      <Index.Box className="dashboard-content user-list-content">
                        <Index.Box className="user-list-flex">
                          <Index.Typography
                            className="admin-page-title user-list-page-title main-heading"
                            component="h2"
                            variant="h2"
                          >
                            Transaction
                          </Index.Typography>

                          <Index.Box className="userlist-btn-flex res-flex">
                            <Index.Box className="header-date-picker add-user-input"></Index.Box>
                            <Index.Box className="user-search-main">
                              <Index.Box className="user-search-box flex-search-details">
                                <Index.Box className="form-group search-box-bg">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Search"
                                    autoComplete="off"
                                    value={stopWhiteSpace(search)}
                                    onChange={(e) => {
                                      // Get the value from the input field
                                      let value = e.target.value;
                          
                                      // Remove any leading spaces
                                      value = value.replace(/^\s+/, '');
                          
                                      // Set the search state with the trimmed value
                                      setSearch(value);
                                    }}
                                    autoComplete="off"
                                    inputProps={{ pattern: "[^\s]*" }}
                                  />
                                  <img
                                    src={PageIndex.Svg.search}
                                    className="search-grey-img"
                                    alt="search grey img"
                                  ></img>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="table-bg-main table-main-box">
                          <Index.Box className="admin-dashboard-list-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                className="display-row"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-dash-box pb-0">
                                    <Index.Box className="advertiserlist-table-main transaction-table-main page-table-main">
                                      <Index.TableContainer
                                        component={Index.Paper}
                                        className="table-container"
                                      >
                                        <Index.Table
                                          aria-label="simple table"
                                          className="table"
                                        >
                                          <Index.TableHead className="table-head">
                                            <Index.TableRow className="table-row">
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                                width="10%"
                                              >
                                                Date
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                Advertisement Title
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                Network
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                From
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                To
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                Hash
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                Gasless
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                Gasfess
                                              </Index.TableCell>
                                              <Index.TableCell
                                                component="th"
                                                variant="th"
                                                className="table-th"
                                              >
                                                Total Amount($)
                                              </Index.TableCell>
                                            </Index.TableRow>
                                          </Index.TableHead>
                                          <Index.TableBody className="table-body">
                                            {filteredData &&
                                              filteredData?.length > 0 ? (
                                              filteredData?.map((row) => (
                                                <Index.TableRow
                                                  // key={row.name}
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  }}
                                                >
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {moment(
                                                      row?.createdAt
                                                    ).format("MMM D, YYYY")}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    -
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    -
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                  >
                                                    <LightTooltip
                                                      title={
                                                        row?.TransactionId?.from
                                                      }
                                                      arrow
                                                      placement="bottom"
                                                    >
                                                      <Index.Typography
                                                        className="meta-drop-title"
                                                        component="p"
                                                        variant="p"
                                                      >
                                                        {`${row?.TransactionId?.from.slice(
                                                          0,
                                                          7
                                                        )}....${row?.TransactionId?.from.slice(
                                                          -7
                                                        )}`}
                                                      </Index.Typography>
                                                    </LightTooltip>
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    <LightTooltip
                                                      title={
                                                        row?.TransactionId?.to
                                                      }
                                                      arrow
                                                      placement="bottom"
                                                    >
                                                      <Index.Typography
                                                        className="meta-drop-title"
                                                        component="p"
                                                        variant="p"
                                                      >
                                                        {`${row?.TransactionId?.to.slice(
                                                          0,
                                                          7
                                                        )}....${row?.TransactionId?.to.slice(
                                                          -7
                                                        )}`}
                                                      </Index.Typography>
                                                    </LightTooltip>
                                                  </Index.TableCell>

                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    <LightTooltip
                                                      title={
                                                        row?.TransactionId
                                                          ?.transactionHash
                                                      }
                                                      arrow
                                                      placement="bottom"
                                                    >
                                                      <Index.Typography
                                                        className="meta-drop-title"
                                                        component="p"
                                                        variant="p"
                                                      >
                                                        <a
                                                          href={
                                                            row?.TransactionId
                                                              ?.url
                                                          }
                                                          target="_blank"
                                                          className="url-link"
                                                        >
                                                          {`${row?.TransactionId?.transactionHash.slice(
                                                            0,
                                                            7
                                                          )}....${row?.TransactionId?.transactionHash.slice(
                                                            -7
                                                          )}`}
                                                        </a>
                                                      </Index.Typography>
                                                    </LightTooltip>
                                                  </Index.TableCell>

                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {row?.TransactionId
                                                      ?.gasless == true
                                                      ? "True"
                                                      : "False"}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {
                                                      row?.TransactionId
                                                        ?.gasFees
                                                    }
                                                  </Index.TableCell>

                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    className="table-td"
                                                  >
                                                    {row?.TransactionId?.value}
                                                  </Index.TableCell>
                                                </Index.TableRow>
                                              ))
                                            ) : loading ? (
                                              <SkeletonTable colSpan={9} />
                                            ) : (
                                              <Index.TableRow
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                                }}
                                              >
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  scope="row"
                                                  className="table-td no-record-text"
                                                  align="center"
                                                  colSpan={9}
                                                >
                                                  <h3>
                                                    {" "}
                                                    No records available{" "}
                                                  </h3>
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            )}
                                          </Index.TableBody>
                                        </Index.Table>
                                      </Index.TableContainer>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      )}
    </>
  );
}
