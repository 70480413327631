import React, { useEffect, useState } from "react";
import moment from "moment";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";
import { listOfDepositHistory } from "../../../../redux/slices/AdminService";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SkeletonTable from "../../../../components/common/skeletonTable/SkeletonTable";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import dayjs from "dayjs";
import Drawer from "@mui/material/Drawer";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#171423",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px"
  }
}));

function DepositHistory() {
  const [loading, setLoading] = useState(true);
  const [depositHistory, setDepositHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [excel, setExcel] = useState([]);
  const fileName = "AdvertiserDepositList";
  console.log(depositHistory, "depositHistory");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isOpen, setIsOpen] =useState(false);

  useEffect(() => {
    if (openDrawer) {
      setIsOpen(openDrawer);
    } else {
      setOpenDrawer(false);
    }
  }, [openDrawer]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
    setOpenDrawer(false);
  };
  // table pagination

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatch = PageIndex?.useDispatch();
  useEffect(() => {
    dispatch(listOfDepositHistory()).then((res) => {
      setDepositHistory(res?.payload?.data);
      if (res?.payload) {
        setLoading(false);
      }
    });
  }, []);
  useEffect(() => {
    const customHeadings = depositHistory?.map((item) => ({
      "Created Date": moment(item?.createdAt).format("lll"),
      "	Wallet Address": item?.walletAddress,
      Network: item?.network,
      "Amount	": item?.depositAmount
    }));

    setExcel(customHeadings);
  }, [depositHistory]);

  useEffect(() => {
    let filteredItems = depositHistory;
    if(search){
      filteredItems=    depositHistory?.filter((item) => {
        return !search
          ? item
          : item?.walletAddress
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
              item?.network?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.depositAmount
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()) ||
            
                moment(item?.createdAt)
                .format("lll")
                ?.toString()
                .includes(search?.toLowerCase()) ;
      });
    }
     
   
    if (fromDate && toDate) {
      filteredItems = filteredItems.filter((item) => {
        const itemDate = moment(item?.createdAt).startOf("day");
        const startDate = moment(fromDate).startOf("day");
        const endDate = moment(toDate).endOf("day");
        console.log(fromDate, toDate, itemDate, "itemDate");
        return itemDate.isBetween(startDate, endDate, null, "[]");
      });
    }

    setFilteredData(filteredItems);
  }, [depositHistory, search, fromDate, toDate]);
  const clearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setFilteredData(depositHistory);
  };

  const stopWhiteSpace = (val) => {
    if (!val) {
      return "";
    }
    const checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    } else {
      return undefined;
    }
  };

  return (
    <Index.Box className="dashboard-content user-list-content">
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title main-heading"
          component="h2"
          variant="h2"
        >
          Deposit History
        </Index.Typography>

        <Index.Box className="userlist-btn-flex res-flex">
          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box flex-search-details">
              <Index.Box className="form-group search-box-bg">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => {
                    // Get the value from the input field
                    let value = e.target.value;
        
                    // Remove any leading spaces
                    value = value.replace(/^\s+/, '');
        
                    // Set the search state with the trimmed value
                    setSearch(value);
                  }}
                  autoComplete="off"
                  inputProps={{ pattern: "[^\s]*" }}
                  value={stopWhiteSpace(search)}
                />
                <img
                  src={PageIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
              <Index.Box className="filter-flex-content">
              <Index.ClickAwayListener onClickAway={handleTooltipClose} >
                <Index.Tooltip title="Filter" arrow>
                <Index.Box className="border-btn-main">
                <Index.Button
                  className="admin-bergur-button custom-ui-btn border-btn m-width-unset"
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                >
                  <img src={Index.Png.filterwhite} className="filterehite" alt="filter"/>
                  {/* <PageIndex.FilterDrawer/> */}
                </Index.Button>
                </Index.Box>
                </Index.Tooltip>
              </Index.ClickAwayListener>
              <Index.Box className="w-100-fill-res">
                <FileExcel apiData={excel} fileName={fileName} />
              </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="table-bg-main table-main-box">
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12"
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box pb-0">
                  <Index.Box className="advertiserlist-table-main deposit-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="15%"
                            >
                              Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                                    width="15%"
                            >
                              Wallet Address
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                                    width="15%"
                            >
                              Network
                            </Index.TableCell>
  
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                                    width="15%"
                            >
                              Amount
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filteredData && filteredData?.length > 0 ? (
                            filteredData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              ?.map((row, index) => (
                                <Index.TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0
                                    }
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {moment(row?.createdAt).format("lll")}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <LightTooltip
                                      title={row?.walletAddress}
                                      arrow
                                      placement="bottom" 
                                    >
                                      <Index.Typography
                                        className="meta-drop-title"
                                        component="p"
                                        variant="p"
                                      >
                                        {row?.walletAddress
                                          ? `${row?.walletAddress?.slice(
                                              0,
                                              7
                                            )}....${row?.walletAddress?.slice(
                                              -7
                                            )}`
                                          : "-"}
                                      </Index.Typography>
                                    </LightTooltip>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.network}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.depositAmount}
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                          ) : loading ? (
                            <>
                              <SkeletonTable colSpan={4} />
                            </>
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td no-record-text"
                                align="center"
                                colSpan={8}
                              >
                                <h3> No records available </h3>
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={filteredData?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
       {/* filter side  */}

       <React.Fragment key="right" className="mui-drawer-filter">
        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)} className="common-drawer-toggle">
          <Index.Box className="common-main-drawer">
            <Index.Box role="presentation" className="common-drawer-details">
              <Index.Box className="common-pd-drawer">
                <Index.Box className="drawer-header">
                  <Index.Box className="common-filter-content">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="common-filter-title"
                    >
                      Filter
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                
                <Index.Box className="drawer-details-hgt">
                  <Index.Box className="drawer-inner-content">
                    {/* filter date */}
                    <Index.Box className="filter-input-field">
                      <Index.Grid
                        container
                        spacing={2}
                        className="common-admin-grid"
                      >
                        {/* filter date */}
                        <Index.Grid
                          item
                          xs={12}
                          className="common-admin-grid-item"
                        >
                          <Index.Box className="booking-report-content">
                            <Index.Box className="common-btn-details">
                              <Index.Box className="flex-gap-footer end-justify-content">
                                <Index.Button
                                  className="btn-secondary"
                                  onClick={clearFilters}
                                  disabled={!fromDate && !toDate}
                                >
                                  Clear
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid
                          item
                          xs={12}
                          className="common-admin-grid-item"
                        >
                          <Index.Box className="common-input-field">
                            <Index.FormHelperText className="common-label-details">
                              From Date
                            </Index.FormHelperText>
                            <Index.Box className="input-common-field">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DatePicker
                                  fullWidth
                                  id="fromDate"
                                  name="fromDate"
                                  className="common-textfield-content input-w-100"
                                 format="DD-MM-YYYY"
                                  placeholder="Add from date"
                                  value={fromDate ? dayjs(fromDate) : null}
                                  slotProps={{
                                    textField: {
                                      error: false
                                    }
                                  }}
                                  maxDate={dayjs()}
                                  onChange={(date) => {
                                    setFromDate(date ? date.toDate() : null);
                                    if (!toDate || date >= toDate) {
                                      setToDate(null);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      sx={{ width: "100%" }}
                                    />
                                  )}
                                />
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid
                          item
                          xs={12}
                          className="common-admin-grid-item"
                        >
                          <Index.Box className="common-input-field">
                            <Index.FormHelperText className="common-label-details">
                              To Date
                            </Index.FormHelperText>
                            <Index.Box className="input-common-field">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DatePicker
                                  fullWidth
                                  id="toDate"
                                  name="toDate"
                                  className="common-textfield-content input-w-100"
                                 format="DD-MM-YYYY"
                                  placeholder="Add to date"
                                  value={toDate ? dayjs(toDate) : null}
                                  onChange={(date) => {
                                    setToDate(date ? date.toDate() : null);
                                  }}
                                  minDate={fromDate ? dayjs(fromDate) : null}
                                  maxDate={dayjs()}
                                  disabled={!fromDate}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      sx={{ width: "100%" }}
                                    />
                                  )}
                                />
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="drawer-footer">
                  <Index.Box className="common-btn-details">
                    <Index.Box className="flex-gap-footer end-justify-content border-btn-main">
                      <Index.Button className="border-btn" onClick={toggleDrawer(false)}>
                        Close
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Drawer>
      </React.Fragment>
    </Index.Box>
  );
}

export default DepositHistory;
