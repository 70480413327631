import React, { useState, useEffect } from "react";
import Index from "../../Index";

const Pagination = ({ fetchData, setPageData, pageData, search, filterBy }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredData = filterBy
      ? fetchData.filter((item) => item.category === filterBy)
      : fetchData;
    const records = filteredData.slice(firstIndex, lastIndex);
    const numbersOfPage = Math.ceil(filteredData.length / recordsPerPage);
    setPageCount(numbersOfPage);
    const serialNumber = (currentPage - 1) * recordsPerPage + 1;
    setPageData(
      records.map((ele, index) => ({
        ...ele,
        sNo: serialNumber + index,
      }))
    );
    if (records.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, fetchData, search, filterBy, setPageData]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  return (
    <>
      {pageData?.length !== 0 && (
        <Index.Box className="pagination-main">
          <Index.Pagination
            count={pageCount}
            page={currentPage}
            onChange={(e, value) => handlePageChange(e, value)}
            variant="outlined"
            shape="rounded"
            className="pagination"
          />
        </Index.Box>
      )}
    </>
  );
};

export default Pagination;
