import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import moment from "moment/moment";
import {
  activeDeactiveAdvertisement,
  adminAdvertisementListData,
  approveRejectAdvertisement
} from "../../../../redux/slices/AdminService";
import "./adminadvertisementlist.css";
import RejectModal from "../../../../components/common/rejectModal/RejectModal";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SkeletonTable from "../../../../components/common/skeletonTable/SkeletonTable";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px"
  }
}));

export default function AdminAdvertisementListNew() {
  const [loading, setLoading] = useState(true);
  const [adminAdvertisementList, setAdminAdvertisementList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [advertisementRow, setAdvertisementRow] = useState();
  const [reason, setReason] = useState();
  const [excel, setExcel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const open1 = Boolean(anchorEl);
  const [singleChallangeData, setSingleChallangeData] = useState({});
  const fileName = "Advertisements file";
  const dispatch = PageIndex?.useDispatch();
  const navigate = PageIndex?.useNavigate();

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setReason("");
  };

  useEffect(() => {
    dispatch(adminAdvertisementListData()).then((res) => {
      setAdminAdvertisementList(res?.payload?.data);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, []);

  const handleApproveRejectAdvertisement = async (id, status) => {
    const data = {
      id,
      status
    };

    if (status === "Rejected") {
      data.rejectReason = reason;
    }
    dispatch(approveRejectAdvertisement(data)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(adminAdvertisementListData()).then((res) => {
          setAdminAdvertisementList(res?.payload?.data);
          setLoading(false);
          setDeleteOpen(false);
        });
      }
    });
  };

  const handleActiveDeactiveAdvertisement = async (id, status) => {
    const data = {
      id,
      status: status.toString()
    };
    dispatch(activeDeactiveAdvertisement(data)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(adminAdvertisementListData()).then((res) => {
          setAdminAdvertisementList(res?.payload?.data);
          setLoading(false);
        });
      }
    });
  };

  useEffect(() => {
    const customHeadings = adminAdvertisementList?.map((item) => ({
      Title: item?.title,
      MaxSpendLimitPerTransaction: item?.maxSpendLimitPerTransaction,
      IsApproved: item?.isApproved,
      Category_Name: item?.category?.Category_Name,
      StartDate: moment(item.startDate).format("DD/MM/YYYY"),
      EndDate: moment(item.endDate).format("DD/MM/YYYY")
    }));

    setExcel(customHeadings);
  }, [adminAdvertisementList]);

  useEffect(() => {
    console.log(search, "search");
    const filtered =
      adminAdvertisementList &&
      adminAdvertisementList?.filter((item) => {
              const categoryMatch = item?.category?.some(
        (category) => category?.Category_Name?.toLowerCase().includes(search?.toLowerCase())
      );

        

        return !search
          ? item
          : item?.title?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.isApproved?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.category?.Category_Name?.toLowerCase().includes(
                search?.toLowerCase()
              ) ||
              item?.addType
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()) ||
              item?.maxSpendLimitPerTransaction
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()) ||
              item?.advertiserId?.email
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()) ||
                categoryMatch||
              moment(item?.startDate)
                .format("DD-MM-YYYY")
                ?.toString()
                .includes(search?.toLowerCase()) ||
              moment(item?.endDate)
                .format("DD-MM-YYYY")
                ?.toString()
                .includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [adminAdvertisementList, search]);

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleActionOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  const stopWhiteSpace = (val) => {
    if (!val) {
      return "";
    }
    const checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    } else {
      return undefined;
    }
  };

  return (
    <>
  
        <Index.Box className="dashboard-content user-list-content advertisement-wrapper advertiset-new-list">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Advertisement
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box flex-search-details">
                  <Index.Box className="form-group search-box-bg">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      value={stopWhiteSpace(search)}
                      onChange={(e) => {
                        // Get the value from the input field
                        let value = e.target.value;
            
                        // Remove any leading spaces
                        value = value.replace(/^\s+/, '');
            
                        // Set the search state with the trimmed value
                        setSearch(value);
                      }}
                      autoComplete="off"
                      inputProps={{ pattern: "[^\s]*" }}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                  <Index.Box>
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12"
                  }}
                  className="grid-column"
                >
                  <Index.Box className="table-bg-main table-main-box">
                    <Index.Box className="admin-dash-box pb-0">
                      <Index.Box className="advertiserlist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="4%"
                                >
                                  Action
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="7%"
                                >
                                  Active/Inactive
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="13%"
                                >
                                  Title
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="10%"
                                >
                                  Category
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Type
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="10%"
                                >
                                  Advertiser
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="20%"
                                >
                                  Max Spend Limit Per Transaction
                                </Index.TableCell>
             
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="10%"
                                >
                                  Start Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                   width="10%"
                                >
                                  End Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                   width="10%"
                                >
                                  Reject Reason
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="10%"
                                >
                                  Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="14%"
                                >
                                  Advertisement Status
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            {pageData && pageData?.length > 0 ? (
                              <Index.TableBody className="table-body">
                                {pageData &&
                                  pageData.map((row, index) => (
                                    <Index.TableRow
                                      key={row?._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0
                                        }
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td action-tab"
                                      >
                                        <Index.IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-haspopup="true"
                                          onClick={(event) => {
                                            handleActionOpen(event);
                                            setSingleChallangeData(
                                              pageData?.[index]
                                            );
                                          }}
                                          
                                        >
                                          <Index.MoreVertIcon />
                                        </Index.IconButton>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          <Index.Switch
                                            className="cus-switch"
                                            checked={row?.isActive}
                                            onChange={(e) =>
                                              handleActiveDeactiveAdvertisement(
                                                row?._id,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </Index.Box>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row.title}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        { row?.category?.length > 0 ? row?.category
                                          ?.map((item) => item?.Category_Name)
                                          .filter(Boolean)
                                          .map(
                                            (name) =>
                                              name.charAt(0).toUpperCase() +
                                              name.slice(1)
                                          )
                                          .join(", "): "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.addType.charAt(0).toUpperCase() +
                                          row.addType.slice(1)}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.advertiserId?.email}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.maxSpendLimitPerTransaction}
                                      </Index.TableCell>
             
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.startDate
                                          ? moment.utc(row?.startDate).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.endDate
                                          ? moment.utc(row?.endDate).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Index.TableCell>

                                      {row?.isApproved === "Approved" ||
                                      row?.isApproved === "Pending" ? (
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          -
                                        </Index.TableCell>
                                      ) : (
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          <LightTooltip
                                            title={row?.rejectReason}
                                            arrow
                                            placement="bottom" 
                                          >
                                            <Index.Typography
                                              className="meta-drop-title"
                                              component="p"
                                              variant="p"
                                            >
                                              {`${row?.rejectReason.slice(
                                                0,
                                                6
                                              )}....`}
                                            </Index.Typography>
                                          </LightTooltip>
                                        </Index.TableCell>
                                      )}

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td status-col"
                                      >
                                        <div
                                          className={
                                            row?.isApproved === "Approved"
                                              ? "table-td-active"
                                              : row?.isApproved === "Rejected"
                                              ? "table-td-Inactive"
                                              : "table-td-Pending"
                                          }
                                        >
                                          {row?.isApproved === "Approved"
                                            ? "Approved"
                                            : row?.isApproved === "Rejected"
                                            ? "Rejected"
                                            : "Pending"}
                                        </div>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td status-col"
                                      >
                                        <div
                                          className={
                                            row?.isRunning === true
                                              ? "table-td-running"
                                              : "table-td-pause"
                                          }
                                        >
                                          {row?.isRunning === true
                                            ? "Running"
                                            : "Pause"}
                                        </div>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  ))}
                              </Index.TableBody>
                               ) : loading ? (
                                <>
                                  <SkeletonTable colSpan={9} />
                                </>
                              ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td no-record-text"
                                  align="center"
                                  colSpan={8}
                                >
                                  <h3> No records available </h3>
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.Table>
                        </Index.TableContainer>

                        {/* Action Menu */}
                        <Index.Box className="admin-menu-box">
                          <Index.Menu
                            className="admin-menu-main"
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button"
                            }}
                            anchorEl={anchorEl}
                            open={open1}
                            onClose={handleActionClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "auto",
                                boxShadow: "none",
                                border: "1px solid rgba(224, 224, 224, 1)",
                                marginTop: "6px"
                              }
                            }}
                          >
                            <Index.MenuItem
                              key={`check_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                disabled={
                                  singleChallangeData?.isApproved ===
                                    "Approved" ||
                                  singleChallangeData?.isApproved === "Rejected"
                                }
                                onClick={() =>
                                  handleApproveRejectAdvertisement(
                                    singleChallangeData?._id,
                                    "Approved"
                                  )
                                }
                              >
                                <Index.CheckIcon className="table-td-active" />
                              </Index.IconButton>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`close_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                disabled={
                                  singleChallangeData?.isApproved ===
                                    "Rejected" ||
                                  singleChallangeData?.isApproved === "Approved"
                                }
                                onClick={() => {
                                  setAdvertisementRow(singleChallangeData);
                                  handleDeleteOpen();
                                }}
                              >
                                <Index.CloseIcon className="table-td-Inactive" />
                              </Index.Button>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`view_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate("/admin/view-admin-advertisement", {
                                    state: singleChallangeData
                                  });
                                }}
                              >
                                <Index.RemoveRedEyeIcon className="view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>
                          </Index.Menu>
                        </Index.Box>
                        {/* End Action Menu */}
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <PageIndex.Pagination
                          fetchData={filteredData}
                          setPageData={setPageData}
                          pageData={pageData}
                          search={search}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
   

      <RejectModal
        deleteOpen={deleteOpen}
        handleDeleteClose={handleDeleteClose}
        reason={reason}
        setReason={setReason}
        handleReject={handleApproveRejectAdvertisement}
        row={advertisementRow}
      />
    </>
  );
}
