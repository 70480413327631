import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { Skeleton } from "@mui/material";
import MvpLoader from "../../../../components/common/mvpLoader/MvpLoader";
import moment from "moment/moment";
import {
  activeDeactiveAdvertiser,
  approveRejectAdvertiser,
  getListAllAdvertiserList
} from "../../../../redux/slices/AdminService";
import RejectModal from "../../../../components/common/rejectModal/RejectModal";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";
import SkeletonTable from "../../../../components/common/skeletonTable/SkeletonTable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#171423",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px"
  }
}));

export default function AdvertiserList() {
  const [loading, setLoading] = useState(true);
  const [advertiserList, setAdvertiserList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const navigate = PageIndex?.useNavigate();
  const dispatch = PageIndex?.useDispatch();
  const open1 = Boolean(anchorEl);
  const [excel, setExcel] = useState([]);
  const fileName = "myfile";
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [advertiserRow, setAdvertiserRow] = useState();
  const [reason, setReason] = useState();

  const [singleChallangeData, setSingleChallangeData] = useState({});

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  // Get all advertisement list
  const getAllAdvertiserList = async () => {
    dispatch(getListAllAdvertiserList()).then((res) => {
      setAdvertiserList(res?.payload?.data);
      setPageData(res?.payload?.data);
      setLoading(false);
    });
  };

  const handleApproveRejectAdvertiser = async (id, status) => {
    const data = {
      id,
      status
    };
    if (status === "Rejected") {
      data.rejectReason = reason;
    }

    dispatch(approveRejectAdvertiser(data)).then((res) => {
      if (res?.payload?.status === 200 || res?.payload?.status === 201) {
        getAllAdvertiserList();
        setLoading(false);
        setDeleteOpen(false);
      }
    });
  };
  const handleActiveDeactiveAdvertiser = async (id, status) => {
    const data = {
      id,
      status: String(status)
    };
    dispatch(activeDeactiveAdvertiser(data)).then((res) => {
      if (res?.payload?.status === 200 || res?.payload?.status === 201) {
        getAllAdvertiserList();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const customHeadings = advertiserList?.map((item) => ({
      Name: item?.name,
      Company: item?.company,
      Email: item?.email,
      IsApproved: item?.isApproved,
      CreatedAt: moment(item.createdAt).format("DD/MM/YYYY")
    }));

    setExcel(customHeadings);
  }, [advertiserList]);

  useEffect(() => {
    const filtered =
      advertiserList &&
      advertiserList?.filter((item) => {
        return !search
          ? item
          : item?.company?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.email?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.isApproved?.toLowerCase().includes(search?.toLowerCase()) ||
              moment(item?.createdAt)
                .format("DD-MM-YYYY")
                ?.toString()
                .includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [advertiserList, search]);
  // region useEffects
  useEffect(() => {
    getAllAdvertiserList();
  }, []);

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleActionOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  const stopWhiteSpace = (val) => {
    if (!val) {
      return "";
    }
    const checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    } else {
      return undefined;
    }
  };
  return (
    <>
      <Index.Box className="dashboard-content user-list-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title main-heading"
            component="h2"
            variant="h2"
          >
            Advertiser
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box flex-search-details">
                <Index.Box className="form-group search-box-bg">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    value={stopWhiteSpace(search)}
                    onChange={(e) => {
                      // Get the value from the input field
                      let value = e.target.value;
          
                      // Remove any leading spaces
                      value = value.replace(/^\s+/, '');
          
                      // Set the search state with the trimmed value
                      setSearch(value);
                    }}
                    autoComplete="off"
                    inputProps={{ pattern: "[^\s]*" }}
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
                <Index.Box>
                  <FileExcel apiData={excel} fileName={fileName} />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12"
                }}
                className="grid-column"
              >
                <Index.Box className="table-bg-main table-main-box">
                  <Index.Box className="admin-dash-box pb-0">
                    <Index.Box className="advertiser-table-main page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="8%"
                              >
                                Action 
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="12%"
                              >
                                Active/Inactive
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="12%"
                              >
                                Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="12%"
                              >
                                Wallet
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="15%"
                              >
                                Company
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="12%"
                              >
                                Email
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="10%"
                              >
                                Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="10%"
                              >
                                Status
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          {pageData && pageData?.length > 0 ? (
                            <Index.TableBody className="table-body">
                              {pageData.length ? (
                                pageData.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0
                                      }
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td action-tab"
                                    >
                                      <Index.IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-haspopup="true"
                                        onClick={(event) => {
                                          handleActionOpen(event);
                                          setSingleChallangeData(
                                            pageData?.[index]
                                          );
                                        }}
                                        //  sx={{ filter: "brightness(0%)" }}
                                      >
                                        <Index.MoreVertIcon />
                                      </Index.IconButton>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Box className="userdata-btn-flex">
                                        <Index.Switch
                                          className="cus-switch"
                                          checked={row?.isActive}
                                          onChange={(e) =>
                                            handleActiveDeactiveAdvertiser(
                                              row?._id,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </Index.Box>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row.name || "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.walletAmount.toFixed(20)}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.company || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.email || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? moment(row?.createdAt).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td status-col"
                                    >
                                      <div
                                        className={
                                          row?.isApproved === "Approved"
                                            ? "table-td-active"
                                            : row?.isApproved === "Rejected"
                                            ? "table-td-Inactive"
                                            : "table-td-Pending"
                                        }
                                      >
                                        {row?.isApproved === "Approved"
                                          ? "Approved"
                                          : row?.isApproved === "Rejected"
                                          ? "Rejected"
                                          : "Pending"}
                                      </div>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0
                                    }
                                  }}
                                >
                                  <Index.TableCell align="center" colSpan={5}>
                                    No record available
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          ) : loading ? (
                            <>
                              <SkeletonTable colSpan={8} />
                            </>
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td no-record-text"
                                align="center"
                                colSpan={8}
                              >
                                <h3> No records available </h3>
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.Table>
                      </Index.TableContainer>

                      {/* Action Menu */}
                      <Index.Box className="admin-menu-box">
                        <Index.Menu
                          className="admin-menu-main"
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button"
                          }}
                          anchorEl={anchorEl}
                          open={open1}
                          onClose={handleActionClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "auto",
                              boxShadow: "none",
                              border: "1px solid rgba(224, 224, 224, 1)",
                              marginTop: "6px"
                            }
                          }}
                        >
                          <LightTooltip title={"Approve"}>
                            <Index.MenuItem
                              key={`check_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                disabled={
                                  singleChallangeData?.isApproved ===
                                    "Approved" ||
                                  singleChallangeData?.isApproved === "Rejected"
                                }
                                onClick={() =>
                                  handleApproveRejectAdvertiser(
                                    singleChallangeData?._id,
                                    "Approved"
                                  )
                                }
                              >
                                <Index.CheckIcon className="table-td-active" />
                              </Index.Button>
                            </Index.MenuItem>
                          </LightTooltip>

                          <LightTooltip title={"Reject"}>
                            <Index.MenuItem
                              key={`delete_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                disabled={
                                  singleChallangeData?.isApproved ===
                                    "Rejected" ||
                                  singleChallangeData?.isApproved === "Approved"
                                }
                                onClick={() => {
                                  setAdvertiserRow(singleChallangeData);
                                  handleDeleteOpen();
                                }}
                              >
                                <Index.CloseIcon className="table-td-Inactive" />
                              </Index.Button>
                            </Index.MenuItem>
                          </LightTooltip>

                          <LightTooltip title={"View"}>
                            <Index.MenuItem
                              key={`view_`}
                              onClick={handleActionClose}
                              className="menu-option-li"
                            >
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  navigate("/admin/view-advertiser", {
                                    state: singleChallangeData
                                  });
                                }}
                              >
                                <Index.RemoveRedEyeIcon className="view-ic" />
                              </Index.IconButton>
                            </Index.MenuItem>
                          </LightTooltip>
                        </Index.Menu>
                      </Index.Box>
                      {/* End Action Menu */}
                    </Index.Box>
                    <PageIndex.Pagination
                      fetchData={filteredData}
                      setPageData={setPageData}
                      pageData={pageData}
                      search={search}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <RejectModal
        deleteOpen={deleteOpen}
        handleDeleteClose={handleDeleteClose}
        reason={reason}
        setReason={setReason}
        handleReject={handleApproveRejectAdvertiser}
        row={advertiserRow}
      />
    </>
  );
}
