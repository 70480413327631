// import loadinggif from './images/gif/loading.gif';
import ActiveAdvertisement from './images/video/ActiveAdvertisement.gif'
import BuybackWallet from './images/video/BuybackWallet.gif'
import CompanyWallet from './images/video/CompanyWallet.gif'
import GasFeeWallet from './images/video/GasFeeWallet.gif'
import Revenue from './images/video/Revenue.gif'
import sponseredgasfees from './images/video/sponseredgasfees.gif'
import TotalAdvertisement from './images/video/TotalAdvertisement.gif'
import TotalAdvertiser from './images/video/TotalAdvertiser.gif'
import TotalWalletUsers from './images/video/TotalWalletUsers.gif'
import Loader_mvp from './images/video/loader_mvp.gif'







const Gif = {
      // loadinggif,
      ActiveAdvertisement,
      BuybackWallet,
      CompanyWallet,
      GasFeeWallet,
      Revenue,
      sponseredgasfees,
      TotalAdvertisement,
      TotalAdvertiser,
      TotalWalletUsers,
      Loader_mvp

}

export default Gif;