import React, { useEffect, useState } from "react";
import {
  advertisementListData,
  deleteAdvertisementData,
  pauseResAdvertisementData,
} from "../../../../redux/slices/AdminService";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import DeleteModal from "../../../../components/common/deleteModal/DeleteModal";
import moment from "moment/moment";
import "./advertisementlist.css";
import "./advertisementlist.responsive.css";
import FileExcel from "../../../../components/common/fileExcel/FileExcel";
import SkeletonTable from "../../../../components/common/skeletonTable/SkeletonTable";
import { useLocation } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";

// for modal design
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "13px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px"
  }
}));
export default function AdvertisementList() {
  const location = useLocation();
  const activeData = location?.state?.activeData;
  console.log(activeData, "123")

  const [loading, setLoading] = useState(true);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const ITEM_HEIGHT = 48;
  const [filteredData, setFilteredData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  console.log(search);

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const dispatch = PageIndex?.useDispatch();
  const navigate = PageIndex?.useNavigate();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [excel, setExcel] = useState([]);
  const fileName = "myfile";
  const handleMenuOpen = (event, row) => {
    setMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const getAllAdvertisementList = async () => {
    dispatch(advertisementListData()).then((res) => {
      setAdvertisementList(res?.payload?.data);
      setPageData(res?.payload?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllAdvertisementList();
  }, []);

  const handleDeleteAdvertisement = () => {
    const idData = {
      _id: selectedRow?._id,
    };
    dispatch(deleteAdvertisementData(idData)).then((res) => {
      if (res?.payload?.status === 200) {
        handleMenuClose();
        dispatch(advertisementListData()).then((res) => {
          setAdvertisementList(res?.payload?.data);
          handleDeleteClose();
        });
      }
    });
  };
  const handlePauseRestartAdvertisement = () => {
    if (selectedRow?.isRunning === true) {
      const idPauseData = {
        id: selectedRow?._id,
        action: "pause",
      };
      dispatch(pauseResAdvertisementData(idPauseData)).then((res) => {
        if (res?.payload?.status === 200) {
          handleMenuClose();
          dispatch(advertisementListData()).then((res) => {
            setAdvertisementList(res?.payload?.data);
          });
        }
      });
    } else {
      const idRestartData = {
        id: selectedRow?._id,
        action: "restart",
      };
      dispatch(pauseResAdvertisementData(idRestartData)).then((res) => {
        if (res?.payload?.status === 200) {
          handleMenuClose();
          dispatch(advertisementListData()).then((res) => {
            setAdvertisementList(res?.payload?.data);
          });
        }
      });
    }
  };

  useEffect(() => {

    const activeIds=activeData?.map(data=>data?._id)

    const filtered =
      advertisementList &&
      advertisementList?.filter((item) => {

        const isActiveIdsMatch=activeIds ? activeIds.includes(item?._id) : true
        const categoryMatch = item?.category?.some((category) =>
          category?.Category_Name?.toLowerCase().includes(search?.toLowerCase())
        );
        const searchMatch =
          !search ||
          item?.title?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.isApproved?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.category?.Category_Name?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.maxSpendLimitPerTransaction
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.duration
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          item?.addType
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()) ||
          categoryMatch ||
          moment(item?.startDate)
            .format("MMM D, YYYY")
            ?.toString()
            .includes(search?.toLowerCase()) ||
          moment(item?.endDate)
            .format("MMM D, YYYY")
            ?.toString()
            .includes(search?.toLowerCase());
        // const isRunningMatch = isRunningData ? item?.isRunning === true : true;

        return searchMatch && isActiveIdsMatch

      });

    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [advertisementList, search, activeData]);

  useEffect(() => {
    const customHeadings = advertisementList?.map((item) => ({
      "Start Date": moment(item?.startDate).format("MMM D, YYYY"),
      "End Date": moment(item?.endDate).format("MMM D, YYYY"),
      Title: item?.title,
      "Is Approved": item?.isApproved,
      "Category Name	": item?.category?.Category_Name,
      Duration: item?.duration,
      // MaxGasFeesLimit: item?.maxGasFeesLimit,
      MaxSpendLimitPerTransaction: item?.maxSpendLimitPerTransaction,
    }));

    setExcel(customHeadings);
  }, [advertisementList]);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const stopWhiteSpace = (val) => {
    if (!val) {
      return "";
    }
    const checkSpace = /^\s/.test(val);
    if (!checkSpace) {
      return val;
    } else {
      return undefined;
    }
  };

  const currentDate = moment().format("DD-MM-YYYY");

  return (
    <Index.Box className="dashboard-content user-list-content">
      <Index.Box className="flex-dash-advertisement">
        <Index.Box className="user-list-flex mb-0-user-list advertisement-flex-w-100">
          <Index.Box className="flex-advertisement-details">
            <Index.Typography
              className="admin-page-title user-list-page-title main-heading"
              component="h2"
              variant="h2"
            >
              Advertisement
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex adver-width">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box flex-search-details">
                <Index.Box className="form-group search-box-bg">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    value={stopWhiteSpace(search)}
                    onChange={(e) => {
                      // Get the value from the input field
                      let value = e.target.value;
          
                      // Remove any leading spaces
                      value = value.replace(/^\s+/, '');
          
                      // Set the search state with the trimmed value
                      setSearch(value);
                    }}
                    autoComplete="off"
                    inputProps={{ pattern: "[^\s]*" }}
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
                <Index.Box className="deposit-flex-btn">
                  <Index.Box className="flex-search-details">
                    <FileExcel apiData={excel} fileName={fileName} />
                  </Index.Box>
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    <Index.Button
                      className="adduser-btn btn-primary"
                      onClick={() =>
                        navigate("/advertiser/add-update-advertisment")
                      }
                    >
                      <img
                        src={PageIndex.Svg.plus}
                        className="plus-icon"
                        alt="plus icon"
                      />
                      Add Advertisement
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                  onClick={handleOpenDelete}
                >
                  <img
                    src={PageIndex.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="table-bg-main table-main-box">
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box pb-0">
                  <Index.Box className="advertiserlist-table-main advertiser-advertiserlist-table page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="8%"
                            >
                              Action
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="14%"
                            >
                              Title
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="14%"
                            >
                              Category
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="13%"
                            >
                              Duration
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="20%"
                            >
                              Max Spend LimitPer Transaction
                            </Index.TableCell>
                            {/* <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Max Gas Fees Limit
                                </Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Start Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              End Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Status
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="25%"
                            >
                              Advertisement Status
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        {pageData && pageData?.length > 0 ? (
                          <Index.TableBody className="table-body">
                            {pageData &&
                              pageData.map((row, index) => {
                                const isWithinDateRange =
                                  moment(selectedRow?.startDate).format(
                                    "DD-MM-YYYY"
                                  ) <= currentDate &&
                                  moment(selectedRow?.endDate).format(
                                    "DD-MM-YYYY"
                                  ) >= currentDate;

                                return (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.IconButton
                                        aria-controls="table-actions-menu"
                                        className="dot-btn"
                                        aria-haspopup="true"
                                        onClick={(event) =>
                                          handleMenuOpen(event, row)
                                        }
                                      >
                                        <Index.MoreVertIcon />
                                      </Index.IconButton>
                                      <Index.Menu
                                        id="table-actions-menu"
                                        anchorEl={menuAnchor}
                                        open={Boolean(menuAnchor)}
                                        onClose={handleMenuClose}
                                        className="cus-menu-ul"
                                        PaperProps={{
                                          style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: "auto",
                                            boxShadow: "none",
                                            border:
                                              "1px solid rgba(224, 224, 224, 1)",
                                            marginTop: "6px",
                                          },
                                        }}
                                      >
                                        <Index.MenuItem className="cus-menu-li">
                                          <LightTooltip
                                            title="View"
                                            arrow
                                            placement="bottom"
                                          >
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                navigate(
                                                  "/advertiser/view-advertisment",
                                                  {
                                                    state: selectedRow,
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.RemoveRedEyeIcon className="view-ic" />
                                            </Index.IconButton>
                                          </LightTooltip>
                                        </Index.MenuItem>
                                        <Index.MenuItem className="cus-menu-li">
                                          <LightTooltip
                                            title="Edit"
                                            arrow
                                            placement="bottom"
                                          >
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                navigate(
                                                  "/advertiser/add-update-advertisment",
                                                  {
                                                    state: {
                                                      selectedRow,
                                                      type: "edit",
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.EditIcon className="edit-ic" />
                                            </Index.IconButton>
                                          </LightTooltip>
                                        </Index.MenuItem>
                                        <Index.MenuItem className="cus-menu-li">
                                          <LightTooltip
                                            title="Delete"
                                            arrow
                                            placement="bottom"
                                          >
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={handleDeleteOpen}
                                            >
                                              <Index.DeleteIcon className="table-td-Inactive delete-ic" />
                                            </Index.IconButton>
                                          </LightTooltip>
                                        </Index.MenuItem>

                                        {isWithinDateRange == true &&
                                          selectedRow?.isApproved ==
                                          "Approved" ? (
                                          selectedRow?.isRunning === true ? (
                                            <Index.MenuItem className="cus-menu-li">
                                              <LightTooltip
                                                title="Pause"
                                                arrow
                                                placement="bottom"
                                              >
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() =>
                                                    handlePauseRestartAdvertisement()
                                                  }
                                                >
                                                  <Index.PauseCircleOutlineIcon className="table-td-Inactive" />
                                                </Index.IconButton>
                                              </LightTooltip>
                                            </Index.MenuItem>
                                          ) : (
                                            <Index.MenuItem className="cus-menu-li">
                                              <LightTooltip
                                                title="Restart"
                                                arrow
                                                placement="bottom"
                                              >
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() =>
                                                    handlePauseRestartAdvertisement()
                                                  }
                                                >
                                                  <Index.RestartAltIcon className="table-td-active" />
                                                </Index.IconButton>
                                              </LightTooltip>
                                            </Index.MenuItem>
                                          )
                                        ) : (
                                          ""
                                        )}

                                        <Index.MenuItem className="cus-menu-li">
                                          <LightTooltip
                                            title="Copy"
                                            arrow
                                            placement="bottom"
                                          >

                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() => {
                                                navigate(
                                                  "/advertiser/add-update-advertisment",
                                                  {
                                                    state: {
                                                      selectedRow,
                                                      type: "copy",
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <Index.ContentCopyIcon />
                                            </Index.IconButton>
                                          </LightTooltip>
                                        </Index.MenuItem>
                                      </Index.Menu>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row.title}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.category?.length > 0 ? row?.category
                                        ?.map((item) => item?.Category_Name)
                                        .filter(Boolean)
                                        .map(
                                          (name) =>
                                            name.charAt(0).toUpperCase() +
                                            name.slice(1)
                                        )
                                        .join(", ") : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Math.floor(row.duration)} seconds
                                      {/* {row.duration} seconds */}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.maxSpendLimitPerTransaction}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.startDate
                                        ? moment(row?.startDate).format(
                                          "MMM D, YYYY"
                                        )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.endDate
                                        ? moment(row?.endDate).format(
                                          "MMM D, YYYY"
                                        )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td status-col"
                                    >
                                      <div
                                        className={
                                          row?.isApproved === "Approved"
                                            ? "table-td-active"
                                            : row?.isApproved === "Rejected"
                                              ? "table-td-Inactive"
                                              : "table-td-Pending"
                                        }
                                      >
                                        {row?.isApproved === "Approved"
                                          ? "Approved"
                                          : row?.isApproved === "Rejected"
                                            ? "Rejected"
                                            : "Pending"}
                                      </div>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td status-col"
                                    >
                                      <div
                                        className={
                                          row?.isRunning === true
                                            ? "table-td-running"
                                            : "table-td-pause"
                                        }
                                      >
                                        {row?.isRunning === true
                                          ? "Running"
                                          : "Pause"}
                                      </div>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })}
                          </Index.TableBody>
                        ) : loading ? (
                          <>
                            <SkeletonTable colSpan={8} />
                          </>
                        ) : (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td no-record-text"
                              align="center"
                              colSpan={8}
                            >
                              <h3> No records available </h3>
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <PageIndex.Pagination
                      fetchData={filteredData}
                      setPageData={setPageData}
                      pageData={pageData}
                      search={search}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteClose={handleDeleteClose}
        hanldeDeleteReocrd={handleDeleteAdvertisement}
        handleModalName="Advertisement"
      />
    </Index.Box>
  );
}
