import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import dashboard from "../../../../assets/images/svg/dashboard";
const AdvertApexChat = ({ dashbordData, filterValue }) => {
  console.log(dashbordData, "dashbordData32323");
  const [chartDetails, setChartDetails] = useState([]);
  const [totalAdvertisementsCount, setTotalAdvertisementsCount] = useState([]);
  console.log(chartDetails, "chartDetails")
  console.log(totalAdvertisementsCount, "totalAdvertisementsCount")
  useEffect(() => {
    setChartDetails(dashbordData?.activeTotalShownCountByDate);
    setTotalAdvertisementsCount(dashbordData?.totalShownCountByDate)
  }, [dashbordData]);
  // useEffect(() => {
  //   for (const [key, value] of Object.entries(
  //     dashbordData?.activeTotalShownCountByDate
  //   )) {
  //     console.log(key, value, ":nnnnnnn"); // first one, second two
  //     let obj = {
  //       x: key,
  //       y: value
  //     };

  //     setChartDetails((prev) => [...prev, obj]);
  //   }

  //   for (const [key, value] of Object.entries(
  //     dashbordData?.totalShownCountByDate
  //   )) {
  //     console.log(key, value, ":nnnnnnn"); // first one, second two
  //     let obj = {
  //       x: key,
  //       y: value
  //     };

  //     setTotalAdvertisementsCount((prev) => [...prev, obj]);
  //   }
  // }, [dashbordData]);

  // useEffect(() => {
  //   if (!dashbordData) return;

  //   // Get today's date
  //   const today = dayjs();
  //   let daysToFilter = null; // Default to null for no filtering

  //   if (filterValue === 1) {
  //     daysToFilter = 7; // Filter for 7 days
  //   } else if (filterValue === 2) {
  //     daysToFilter = 14; // Filter for 14 days
  //   }

  //   // Filter function based on daysToFilter
  //   const filterData = (data) => {
  //     return Object.entries(data)
  //       .filter(([date]) => {
  //         // If daysToFilter is null, don't filter, else apply the filter
  //         return daysToFilter === null || today.diff(dayjs(date), "day") <= daysToFilter;
  //       })
  //       .map(([key, value]) => ({
  //         x: key,
  //         y: value,
  //       }));
  //   };

  //   const filteredChartDetails = filterData(dashbordData.activeTotalShownCountByDate);
  //   const filteredTotalAdvertisementsCount = filterData(dashbordData.totalShownCountByDate);

  //   setChartDetails(filteredChartDetails);
  //   setTotalAdvertisementsCount(filteredTotalAdvertisementsCount);
  // }, [dashbordData, filterValue]);

  const dates = [
    { x: "2024-02-06", y: 1500000 },
    { x: "2024-03-12", y: 2000000 },
    { x: "2024-04-08", y: 1000000 },
    { x: "2024-05-02", y: 3000000 },
    { x: "2024-08-16", y: 2500000 }
    // Add more data points as needed
  ];
  console.log(chartDetails, ":chartDetails", dates);
  // const dates = dashbordData[0]?.
  // const [series, setSeries] = useState([
  //   {
  //     name: "Advertisement",
  //     data: chartDetails // Make sure `dates` is defined or passed as a prop
  //   }
  // ]);

  const series = [
    {
      name: "Advertisement",
      data: chartDetails // Make sure `dates` is defined or passed as a prop
    },
    {
      name: "Total Advertisements",
      data: totalAdvertisementsCount
    }
  ];

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      stacked: false,
      height: 290,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: "zoom"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    title: {
      // text: "Stock Price Movement",
      align: "left"
    },
    grid: {
      // borderColor: "#00FF00",
      strokeDashArray: 2,
    },
    stroke: {
      colors: ["#f9cc5d"]
    },
    fill: {
      colors: ["#f9cc5d", "#fe806b", "#4cb58c"],
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val;
        }
      },
      title: {
        text: "Active"
      }
    },
    xaxis: {
      type: "datetime"

    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={290}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default AdvertApexChat;
